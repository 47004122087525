<template>
  <div class="bg-mainFront w-full">
    <router-view></router-view>
  </div>
</template>

<script>
import {
  onBeforeMount,
  ref,
  watch,
} from 'vue';
import walletAPI from '@/os/APIs/walletAPI';
import frontAPI from './frontAPI';

export default {
  name: 'FrontPage',
  setup() {
    const loading = ref(false);

    onBeforeMount(async () => {
      loading.value = true;
      frontAPI.init();
    });

    watch(walletAPI.state.walletData, () => {
      if (walletAPI.state.walletConnected.value) {
        frontAPI.getCollections();
      }
    });

    return {
      loading,
    };
  },
};
</script>

<style scoped>
.bg-mainFront {
  background-color: #1E3236;
}
</style>
