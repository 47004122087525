import tokens from '@/os/values/tokens';

export function getTokenDecimals(token) {
  return tokens[token].decimals;
}

export function tokenNameToHash(tokenName) {
  return tokens[tokenName].hash;
}
export default {
  getTokenDecimals,
  tokenNameToHash,
};
