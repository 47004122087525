<template>
    <div v-if="$os.state.activeModal.value === modalName" class="elementToFadeInAndOut fixed h-full inset-0 bg-black bg-opacity-90 flex justify-center items-center" style="z-index: 1000;">
      <div
        class="modal mx-auto text-white rounded-2xl shadow-lg relative mb-12"
        :style="'height: ' + height + 'px; width: ' + width + 'px'"
      >
        <button
          class="absolute top-4 right-4 outline-none"
          :class="popOut"
          @click="$os.triggerModal()"
        >
          <i class="fas fa-times"></i>
        </button>
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  name: 'modal',
  props: [
    'height',
    'width',
    'modalName',
  ],
  setup() {
    const popOut = 'hover:text-flm-pink transition duration-500 ease-in-out transform hover:scale-110';
    return {
      popOut,
    };
  },
};
</script>

<style scoped>

.modal {
  border: solid 6px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#181818, #181818),
  linear-gradient(to left, #430267 0%, #D73AE0 100%);
  animation: animate-stripes 3s linear infinite alternate;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
    border-color: #AD00FF;
  }
  100% {
    background-position: 6px 0;
  }
}
</style>
