import contractAPI from '@/os/APIs/contractAPI';
import walletAPI from '@/os/APIs/walletAPI';
import InvokeConfig from '@/os/utils/invoke';

function claimPool(stakingContract, pool) {
  const operation = pool.reversePool ? 'claim' : 'claimFLM';
  return {
    scriptHash: stakingContract,
    operation,
    args: [
      {
        type: 'Address',
        value: walletAPI.state.walletData.value.address,
      },
      {
        type: 'Hash160',
        value: contractAPI.getContractByName(pool.symbol).hash,
      },
    ],
  };
}

async function claimAllPools(pools) {
  const invocations = [];

  const allowedContracts = [
    contractAPI.getContractByName('FLM').hash,
    contractAPI.getContractByName('staking').hash,
  ];

  pools.forEach((p) => {
    const pool = contractAPI.getContractByName(p);
    const stakingContract = pool.reversePool ? contractAPI.getContractByName('reverseStaking').hash : contractAPI.getContractByName('staking').hash;
    if (!allowedContracts.includes(stakingContract)) {
      allowedContracts.push(stakingContract);
    }
    if (!allowedContracts.includes(pool.rewardToken.hash)) {
      allowedContracts.push(pool.rewardToken.hash);
    }
    if (!allowedContracts.includes(pool.hash)) {
      allowedContracts.push(pool.hash);
    }

    invocations.push(claimPool(stakingContract, pool));
  });
  return new InvokeConfig(invocations, allowedContracts);
}

export default {
  claimAllPools,
};
