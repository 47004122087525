<template>
  <div class="w-full h-full relative pb-12">
    <img src="/img/frontpage/back.png" class="w-full absolute top-0 back-img" />
    <div class="absolute m-4">
      <a
        href="https://dogerift.com"
        class="relative inline-block px-4 py-2 rounded-full bg-black bg-opacity-40 z-20"
      >
        <i class="fas fa-angle-left mr-1"></i>
        Back to dogerift.com
      </a>
    </div>
    <div class="relative z-10 text-center px-2 md:px-14 flex flex-col items-center pt-32" id="mintPanel">
      <div class="font-lucky color-title text-shadow-title text-[28px] tracking-wide">JOIN THE BORED DOGE CLUB</div>
      <div class="font-lucky color-semi-title text-shadow-title text-5xl tracking-wide mt-6">MINT A BORED DOGE NFT</div>
      <div class="blackBox mt-8 py-6 px-8 text-left" v-if="!walletConnected">
        <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">CONNECT WALLET</div>
        <div class="text-lg tracking-wide color-label">Welcome! Looking to get your hands on a limited Bored Doge NFT? Then you've come to the right place!</div>
        <div class="text-lg tracking-wide color-label mt-6">Please connect your Neo N3 wallet to proceed.</div>
        <div class="flex flex-col justify-center mt-3" v-if="!walletLoading">
          <button
            class="wallet-button"
            @click="connect('NEOLINE')"
          >
            <div class="flex items-center">
              <img :src="require('../../../../public/img/connectWallet/neoline.svg')">
              <div class="ml-2.5 text-lg font-medium">Connect NeoLine</div>
            </div>
            <img src="img/ui/arrow-right.svg" width="24" height="24" />
          </button>
          <button
            class="wallet-button"
            @click="connect('O3')"
          >
            <div class="flex items-center">
              <img :src="require('../../../../public/img/connectWallet/o3.svg')">
              <div class="ml-2.5 text-lg font-medium">Connect O3</div>
            </div>
            <img src="img/ui/arrow-right.svg" width="24" height="24" />
          </button>
          <button
            class="wallet-button"
            @click="connect('WalletConnect')"
          >
            <div class="flex items-center">
              <img :src="require('../../../../public/img/connectWallet/neon.svg')">
              <div class="ml-2.5 text-lg font-medium">Connect NEON Wallet</div>
            </div>
            <img src="img/ui/arrow-right.svg" width="24" height="24" />
          </button>
          <button
            class="wallet-button"
            @click="connect('OneGate')"
          >
            <div class="flex items-center">
              <img :src="require('../../../../public/img/connectWallet/onegate.svg')">
              <div class="ml-2.5 text-lg font-medium">Connect OneGate</div>
            </div>
            <img src="img/ui/arrow-right.svg" width="24" height="24" />
          </button>
        </div>
        <div
          class="flex absolute inset-0 items-center justify-center"
          v-else
        >
          <div>
            <spinner></spinner>
            <div>Connecting...</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="blackBox mt-8 py-6 px-8 text-left flex flex-col justify-between" v-if="mintStage === 1">
          <div>
            <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide ">MINT BORED DOGE NFT</div>
            <div class="text-sm tracking-wide color-label flex justify-between items-center px-2 mt-2">
              Connected Wallet:
              <button class="underline" @click="$wallet.disconnectWallet()">
                Disconnect
              </button>
            </div>
            <div class="rounded-lg info-area p-4 mt-1 flex items-center">
              <img src="../static/check.svg" class="w-5 h-5" />
              <div class="ml-3 text-lg tracking-wide trunc-wallet color-label">{{ walletAPI.state.walletData.value.address }}</div>
            </div>
            <div>
              <div class="text-sm tracking-wide color-label flex justify-between items-center px-2 mt-6">
                Number of Bored Doge NFTs:
              </div>
              <div class="rounded-lg info-area p-4 mt-1 flex items-center">
                <input type="number" min="1" max="50" class="outline-none bg-transparent w-full" v-model="mintAmount" />
              </div>
              <div class="mt-6 pl-2 color-label text-sm tracking-wide">Total Minting Price:</div>
              <div class="rounded-lg info-area p-4 mt-1 flex items-center">
                <img src="../static/DOGER.svg" class="w-5 h-5" />
                <div class="ml-3 text-lg tracking-wide trunc-wallet color-label">{{ (frontAPI.price.value * mintAmount).toFixed(2).toLocaleString() }}</div>
              </div>
            </div>
          </div>
          <div v-if="(frontAPI.price.value * mintAmount) > dogerBalance" class="warning-box mt-1 px-5 py-2">
            Please get more {{ frontAPI.price.value * mintAmount - dogerBalance }} $DOGER tokens in order to proceed.
          </div>
          <div class="flex flex-col md:flex-row gap-1 md:gap-4 mt-4">
            <button
              class="get-doger-btn mt-5 p-4 text-center w-full text-lg font-bold tracking-wide active:opacity-75"
              @click="getDoger"
            >
              Get $DOGER
            </button>
            <button
              class="mt-5 p-4 text-center w-full text-lg font-bold tracking-wide"
              :class="(frontAPI.price.value * mintAmount) > dogerBalance ? 'disable-btn' : 'mint-btn active:opacity-75'"
              @click="mint"
              :disabled="(frontAPI.price.value * mintAmount) > dogerBalance"
            >
              Mint NFT
            </button>
          </div>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left" v-else-if="mintStage === 2">
          <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">MINTING NFT...</div>
          <div class="text-lg tracking-wide color-label">Awaiting confirmation...</div>
          <div
            class="flex absolute inset-0 items-center justify-center"
          >
            <div>
              <spinner></spinner>
            </div>
          </div>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left flex flex-col justify-between" v-else-if="mintStage === 3">
          <div>
            <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">SUCCESS!</div>
            <div class="text-lg tracking-wide color-label">Congratulations, you’ve minted a Bored Doge NFT!</div>
            <div class="text-lg tracking-wide color-label">Transaction:
              <a :href="`https://dora.coz.io/transaction/neo3/mainnet/${txLink}`" target="_blank" class="underline ml-1">
                {{ `https://dora.coz.io/transaction/neo3/mainnet/${txLink.slice(0, 4)}...${txLink.slice(txLink.length - 4, txLink.length)}` }}
              </a>
            </div>
          </div>
          <img src="../static/success-check.svg" class="w-32 h-32 mx-auto" />
          <button
            class="mint-btn mt-[22px] p-4 text-center w-full font-bold tracking-wide active:opacity-75"
            @click="mintStage = 1"
          >
            Done
          </button>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left flex flex-col justify-between" v-else-if="mintStage === 4">
          <div>
            <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">Oops! The BORED DOGE RAN OFF...</div>
            <div class="text-lg tracking-wide color-label">Something went wrong, please try again. Error: ???</div>
          </div>
          <img src="../static/error-info.svg" class="w-32 h-32 mx-auto" />
          <button
            class="mint-btn mt-[22px] p-4 text-center w-full font-bold tracking-wide active:opacity-75"
            @click="mintStage = 1"
          >
            Try again
          </button>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left flex flex-col justify-between" v-if="mintStage === 5">
          <div>
            <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">MINT BORED DOGE NFT</div>
            <div class="text-sm tracking-wide color-label flex justify-between items-center px-2 mt-2">
              Connected Wallet:
              <button class="underline" @click="$wallet.disconnectWallet()">
                Disconnect
              </button>
            </div>
            <div class="rounded-lg info-area p-4 mt-1 flex items-center">
              <img src="../static/check.svg" class="w-5 h-5" />
              <div class="ml-3 text-lg tracking-wide trunc-wallet color-label">{{ walletAPI.state.walletData.value.address }}</div>
            </div>
            <div class="text-sm tracking-wide color-label flex justify-between items-center px-2 mt-5">
              From Token:
              <button class="color-label" @click="setMax">
                <span class="opacity-50">Available: {{ tokenInputMax }}</span>
              </button>
            </div>
            <div class="rounded-lg info-area px-4 mt-1 flex items-center">
              <input type="number" min="1" class="outline-none bg-transparent flex-grow color-label" placeholder="0" v-model="frontAPI.inputAmount.value" />
              <nudes-select
                class="w-36 border-l-2 border-white border-opacity-10 py-4"
                v-model:token="frontAPI.inputToken.value"
              />
            </div>
            <div class="text-sm tracking-wide color-label items-center px-2 mt-5">
              To Token:
            </div>
            <div class="rounded-lg info-area px-4 mt-1 flex items-center">
              <div class="flex-grow flex items-center pr-4">
                <input type="number" min="1" class="outline-none bg-transparent flex-grow color-label" placeholder="0" v-model="frontAPI.outputAmount.value" />
                <div class="ml-2 px-3 color-label rounded-xl text-opacity-75 bg-label">{{ Math.floor(frontAPI.outputAmount.value / frontAPI.price.value) }}x NFT</div>
              </div>
              <div class="w-36 border-l-2 border-white border-opacity-10 p-4 flex items-center">
                <img src="../static/DOGER.svg" class="w-6 h-6 mr-2" />
                <span class="color-label">DOGER</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col md:flex-row gap-1 md:gap-4">
            <button
              class="get-doger-btn mt-5 p-4 text-center w-full text-lg font-bold tracking-wide active:opacity-75"
              @click="mintStage = 1"
            >
              Back
            </button>
            <button
              class="mint-btn mt-5 p-4 text-center w-full text-lg font-bold tracking-wide"
              :class="frontAPI.swapData.value === null ? 'disable-btn' : 'active:opacity-75'"
              @click="convert"
              :disabled="frontAPI.swapData.value === null"
            >
              Convert
            </button>
          </div>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left" v-else-if="mintStage === 6">
          <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">CONVERTING TOKENS</div>
          <div class="text-lg tracking-wide color-label">Awaiting confirmation...</div>
          <div
            class="flex flex-grow h-[342px] items-center justify-center"
          >
            <div>
              <spinner></spinner>
            </div>
          </div>
          <div class="disable-btn w-full p-4 text-center">Continue</div>
        </div>
        <div class="blackBox mt-8 py-6 px-8 text-left flex flex-col justify-between" v-else-if="mintStage === 7">
          <div>
            <div class="font-lucky text-shadow-title text-white text-[32px] tracking-wide">Convert SUCCESS!</div>
            <div class="text-lg tracking-wide color-label">{{ frontAPI.outputAmount.value.toLocaleString(undefined, { maximumFractionDigits: 2 }) }} $DOGER has been added to your wallet.</div>
            <div class="text-lg tracking-wide color-label">Transaction:
              <a :href="`https://dora.coz.io/transaction/neo3/mainnet/${txLink}`" target="_blank" class="underline ml-1">
                {{ `https://dora.coz.io/transaction/neo3/mainnet/${txLink.slice(0, 4)}...${txLink.slice(txLink.length - 4, txLink.length)}` }}
              </a>
            </div>
          </div>
          <img src="../static/success-check.svg" class="w-32 h-32 mx-auto" />
          <button
            class="mint-btn mt-[22px] p-4 text-center w-full font-bold tracking-wide active:opacity-75"
            @click="mintStage = 1"
          >
            Continue
          </button>
        </div>
      </div>
      <!-- My bored Doge collections -->
      <div class="myCollections pt-12 px-4 md:px-12 pb-6 flex flex-col items-center mt-44 w-full" v-if="walletConnected && (frontAPI.myCollections.value.length > 0 || newlyMintedItems.length > 0)">
        <div class="text-white font-lucky text-shadow-title text-4xl tracking-wide">MY BORED DOGE COLLECTION</div>
        <div class="color-label tracking-wide mt-1.5 text-lg hidden md:block">{{ walletAPI.state.walletData.value.address }}</div>
        <div class="color-label tracking-wide mt-1.5 text-lg md:hidden">
          {{ walletAPI.state.walletData.value.address.slice(0, 6) }}...
          {{ walletAPI.state.walletData.value.address.slice(walletAPI.state.walletData.value.address.length - 6, walletAPI.state.walletData.value.address.length) }}
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-8 nice-scroll myBoredNfts">
          <div
            v-for="(item, key) in newlyMintedItems"
            :key="`new-${key}`"
          >
            <img
              :src="item.nft.image"
              :title="item.nft.name"
              class="rounded new-nft-image"
            />
            <div class="bg-black bg-opacity-50 w-full text-center text-shadow-title text-lg tracking-wide new-nft-label font-lucky">NEW</div>
            <div class="font-lucky text-shadow-title text-[26px] nft-name tracking-wide mt-5 truncate-title">{{ item.nft.name }}</div>
            <div class="font-lucky color-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Basic'">BASIC</div>
            <div class="font-lucky color-semi-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Rare'">RARE</div>
            <div class="font-lucky color-green-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Legendary'">LEGENDARY</div>
          </div>
          <div
            v-for="(item, key) in frontAPI.myCollections.value"
            :key="key"
          >
            <img
              :src="item.nft.image"
              :title="item.nft.name"
              class="rounded"
            />
            <div class="font-lucky text-shadow-title text-[26px] nft-name tracking-wide mt-5 truncate-title">{{ item.nft.name }}</div>
            <div class="font-lucky color-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Basic'">BASIC</div>
            <div class="font-lucky color-semi-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Rare'">RARE</div>
            <div class="font-lucky color-green-title text-shadow-title text-xl mt-0.5 tracking-wide" v-if="item.nft.attributes[0].value === 'Legendary'">LEGENDARY</div>
          </div>
        </div>
      </div>
      <!-- DOGE CLUB NFTS -->
      <div class="font-lucky color-title text-shadow-title text-[28px] tracking-wide mt-16 md:mt-20">NFTS</div>
      <div class="font-lucky color-semi-title text-shadow-title text-5xl tracking-wide mt-6">EXPLORE THE BORED DOGE CLUB</div>
      <!-- eslint-disable-next-line max-len -->
      <div class="color-label text-lg tracking-wide w-2/3 mt-6"><b>Bored Doge Club</b> is a limited early NFT collection that aims to provide support for DogeRift. There will be 5,000 digital collectibles on the NEO blockchain. The pack will be Fair Launch with a <b>500M DOGER</b> cost per mint.<br/><br/>
      Bored Doge NFT holders will receive <b>exclusive NFT</b> (2D/3D Characters) and <b>$DOGER</b> airdrops!
      More <b>unique utilities</b> and <b>privileges</b> will be announced soon!</div>
      <div class="mt-16 nftUtiltiyBox p-4 md:p-12">
        <div class="font-lucky color-title text-shadow-title text-4xl tracking-wide">BASIC NFT x1 utility</div>
        <div class="text-white font-lucky text-shadow-title text-xl tracking-wide mt-1.5">Supply: <b>{{ frontAPI.rareNftSupply.value.mint.toLocaleString() }} / {{ frontAPI.rareNftSupply.value.total.toLocaleString() }}</b></div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-8">
          <div
            v-for="(item, key) in frontAPI.rareNft.value"
            :key="key"
          >
            <img
              :src="item.nft.image"
              :title="item.nft.name"
              class="rounded"
            />
            <div class="font-lucky text-shadow-title text-[26px] nft-name tracking-wide mt-5 truncate-title">{{ item.nft.name }}</div>
            <div class="font-lucky text-shadow-title text-white text-opacity-50 text-lg tracking-wide mt-0.5">{{ (item.totalAmount - item.mintAmount)}} / {{ item.totalAmount }} LEFT</div>
          </div>
        </div>
      </div>
      <div class="mt-16 nftRareBox p-4 md:p-12">
        <div class="font-lucky color-semi-title text-shadow-title text-4xl tracking-wide">Rare NFT x3 utility</div>
        <div class="text-white font-lucky text-shadow-title text-xl tracking-wide mt-1.5">Supply: <b>{{ frontAPI.ultraNftSupply.value.mint }} / {{ frontAPI.ultraNftSupply.value.total }}</b></div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-8">
          <div
            v-for="(item, key) in frontAPI.ultraNft.value"
            :key="key"
          >
            <img
              :src="item.nft.image"
              :title="item.nft.name"
              class="rounded"
            />
            <div class="font-lucky text-shadow-title text-[26px] nft-name tracking-wide mt-5 truncate-title">{{ item.nft.name }}</div>
            <div class="font-lucky text-shadow-title text-white text-opacity-50 text-lg tracking-wide mt-0.5">{{ (item.totalAmount - item.mintAmount)}} / {{ item.totalAmount }} LEFT</div>
          </div>
        </div>
      </div>
      <div class="mt-16 nftLegendaryBox p-4 md:p-12">
        <div class="font-lucky color-green-title text-shadow-title text-4xl tracking-wide">Legendary NFT x10 Utility</div>
        <div class="text-white font-lucky text-shadow-title text-xl tracking-wide mt-1.5">Supply: <b>{{ frontAPI.legendNftSupply.value.mint }} / {{ frontAPI.legendNftSupply.value.total }}</b></div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-x-4 gap-y-8 mt-8">
          <div
            v-for="(item, key) in frontAPI.legendNft.value"
            :key="key"
          >
            <img
              :src="item.nft.image"
              :title="item.nft.name"
              class="rounded"
            />
            <div class="font-lucky text-shadow-title text-[26px] nft-name tracking-wide mt-5 truncate-title">{{ item.nft.name }}</div>
            <div class="font-lucky text-shadow-title text-white text-opacity-50 text-lg tracking-wide mt-0.5">{{ (item.totalAmount - item.mintAmount)}} / {{ item.totalAmount }} LEFT</div>
          </div>
        </div>
      </div>
      <div class="font-lucky color-title text-[28px] tracking-wide mt-20">JOIN THE BORED DOGE CLUB</div>
      <div class="color-label text-lg tracking-wide mt-3">Don’t miss out! Mint your Bored Doge from the limited early NFT collection from DogeRift!</div>
      <a
        href="#mintPanel"
        class="mint-btn mt-[22px] py-4 md:px-11 w-full md:w-auto text-center font-bold tracking-wide active:opacity-75 cursor-pointer"
      >
        Mint Bored Doge NFT
      </a>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import walletAPI from '@/os/APIs/walletAPI';
import invokeAPI from '@/os/APIs/invokeAPI';
import rpcAPI from '@/os/APIs/rpcAPI';
import { WrongNetError } from '@/os/blockchain/wallet/walletUtilities';
import Spinner from '@/os/components/Spinner.vue';
import { checkSymbol } from '@/os/utils/calculators';
import { castToRpcHash } from '@/os/utils/convert';
import { getTokenDecimals, tokenNameToHash } from '@/os/utils/tokens';
import BigNumber from 'bignumber.js';
import swapping from '@/os/invocations/swapping';
import wrapping from '@/os/invocations/wrapping';
import frontAPI from '../frontAPI';
import invoke from '../utils/invoke';

export default {
  name: 'FrontPage',
  components: {
    Spinner,
  },
  setup() {
    const store = useStore();
    const walletLoading = ref(false);
    const walletConnected = computed(() => walletAPI.state.walletConnected.value);
    const mintStage = ref(1); // 1: select token, 2: awaiting confirmation, 3: success, 4: error, 5: convert token, 6: converting
    const txLink = ref('');
    const mintAmount = ref(1);
    const newlyMintedItems = ref([]);
    const dogerBalance = computed(() => {
      let bal = 0;
      walletAPI.state.walletData.value.balances.forEach((item) => {
        if (item.symbol === 'DOGER') bal = parseFloat(item.amount);
      });
      return bal;
    });

    const tokenInputMax = computed(() => {
      if (frontAPI.inputToken.value === null || frontAPI.inputToken.value === '') {
        return 'Select pool';
      }

      const tokenDetails = walletAPI.state.walletData.value.balances.find((token) => token.symbol === frontAPI.inputToken.value);

      if (!tokenDetails) return 0;

      return tokenDetails.amount;
    });

    function setMax() {
      frontAPI.isSwapIn.value = true;
      const tokenDetails = walletAPI.state.walletData.value.balances.find((token) => token.symbol === frontAPI.inputToken.value);
      frontAPI.inputAmount.value = tokenDetails.amount;
    }

    async function connect(walletName) {
      walletLoading.value = true;
      store.commit('blockchain/initializeConnection');

      try {
        await walletAPI.connectWallet(walletName);
      } catch (error) {
        if (error instanceof WrongNetError) {
          store.commit('blockchain/clearWallet');
        }
      } finally {
        walletLoading.value = false;
      }
    }

    async function swap() {
      let currentRoute = frontAPI.swapData.value === null ? [] : frontAPI.swapData.value.route.map((r) => r.assetInName);
      currentRoute.push(checkSymbol(frontAPI.outputToken.value));
      currentRoute = currentRoute
        .map((token) => tokenNameToHash(token))
        .map((hash) => castToRpcHash(hash));
      const deadline = await invokeAPI.getDeadline(10 * 60 * 1000);
      const invocations = [];
      let allowedContracts = [];

      if (frontAPI.isSwapIn.value) {
        // check wrap
        if (frontAPI.inputToken.value === 'NEO') {
          const tokenDecimals = getTokenDecimals('NEO');
          const amount = new BigNumber(frontAPI.amount.value).shiftedBy(tokenDecimals);
          const wrapNEO = wrapping.toBurgerToken(amount.toFixed());
          invocations.push(wrapNEO.invocations[0]);
          allowedContracts = [...allowedContracts, ...wrapNEO.allowedContracts];
        }
        const tokenDecimals = getTokenDecimals(checkSymbol(frontAPI.inputToken.value));
        const amount = new BigNumber(frontAPI.amount.value).shiftedBy(tokenDecimals);
        const swapInConfig = swapping.swapIn(currentRoute, amount.toFixed(), frontAPI.swapData.value.amountOutMin.toFixed(), deadline.toFixed());
        invocations.push(swapInConfig.invocations[0]);
        allowedContracts = [...allowedContracts, ...swapInConfig.allowedContracts];
      } else {
        const tokenDecimals = getTokenDecimals(checkSymbol(frontAPI.outputToken.value));
        const amount = new BigNumber(frontAPI.amount.value).shiftedBy(tokenDecimals);
        const swapOutConfig = swapping.swapOut(currentRoute, amount.toFixed(), frontAPI.swapData.value.amountInMax.toFixed(), deadline.toFixed());
        invocations.push(swapOutConfig.invocations[0]);
        allowedContracts = [...allowedContracts, ...swapOutConfig.allowedContracts];
      }
      allowedContracts = [...new Set(allowedContracts)];
      const txId = await walletAPI.invokeWallet(invocations, allowedContracts);
      return txId;
    }

    function checkTxId(txId) {
      const walletLink = `https://dora.coz.io/address/neo3/mainnet/${walletAPI.state.walletData.value.address}`;
      const unknownErrorMessage = `Unknown wallet error. Please check latest transactions <a class="text-pink-400" href="${walletLink}" target="_blank">on Dora</a> to see if transaction went through.`;

      if (txId === false) {
        throw Error(unknownErrorMessage);
      }

      // eslint-disable-next-line
      if (txId?.hasOwnProperty('error')) {
        // eslint-disable-next-line
        if (txId?.error.hasOwnProperty('message')) {
          throw Error(txId.error.message);
        }
        throw Error(unknownErrorMessage);
      }
    }

    async function convert() {
      mintStage.value = 6;
      try {
        const txId = await swap();
        console.log(txId);
        checkTxId(txId);
        await rpcAPI.checkTxStatus(txId);
        txLink.value = txId;
      } catch (error) {
        mintStage.value = 4;
        return;
      }
      setTimeout(async () => {
        console.log('erer');
        await walletAPI.getWalletBalance();
        mintStage.value = 7;
      }, 1000);
    }

    async function mint() {
      if (frontAPI.nftCount.value + mintAmount.value > frontAPI.totalNft.value) {
        window.toast.error(`Only ${frontAPI.totalNft.value - frontAPI.nftCount.value} Nfts can be mintable...`);
        return;
      }
      if (mintAmount.value > 25) {
        window.toast.error('Can only mint 25 or less in one transaction');
        return;
      }
      mintStage.value = 2;
      try {
        const txId = await invoke.mint(mintAmount.value, frontAPI.price.value);
        console.log(txId);
        checkTxId(txId);
        await rpcAPI.checkTxStatus(txId);
        txLink.value = txId;
        const txLog = await rpcAPI.getApplicationLog(txId);
        // eslint-disable-next-line
        txLog.notifications.map((item, idx) => {
          if (idx > 0) {
            const itemName = Buffer.from(item.state.value[3].value, 'base64').toString().split(' #')[0];
            const tmp = frontAPI.allNfts.value.find((nft) => nft.settings.id_name === itemName);
            newlyMintedItems.value.push(tmp);
          }
        });
        await frontAPI.init();
      } catch (error) {
        console.log('mint error', error);
        mintStage.value = 4;
        return;
      }
      mintStage.value = 3;
    }

    function getDoger() {
      frontAPI.outputAmount.value = frontAPI.price.value * mintAmount.value >= dogerBalance.value ? frontAPI.price.value * mintAmount.value : 0;
      frontAPI.inputToken.value = null;
      mintStage.value = 5;
    }

    return {
      convert,
      walletConnected,
      walletLoading,
      connect,
      mintStage,
      walletAPI,
      mint,
      frontAPI,
      dogerBalance,
      tokenInputMax,
      setMax,
      txLink,
      mintAmount,
      getDoger,
      newlyMintedItems,
    };
  },
};
</script>

<style scoped>
.color-title {
  color: #EE7B42;
}

.color-semi-title {
  color: #EDCC38;
}

.color-green-title {
  color: #3BD32B;
}

.color-label {
  color: #FFFDE6;
}

.text-shadow-title {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.6);
}

.blackBox {
  background: rgba(17, 17, 17, 0.64);
  border-radius: 16px;
  min-height: 528px;
  width: 568px;
  position: relative;
}

.myCollections {
  background: rgba(17, 17, 17, 0.32);
  border-radius: 20px;
}

.myBoredNfts {
  max-height: 640px;
  overflow-y: auto;
}

.wallet-button {
  height: 56px;
  border: 3px solid #FFE94F;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  margin-top: 16px;
}

.wallet-button:active {
  opacity: 0.75;
}

.info-area {
  background: rgba(17, 17, 17, 0.64);
}

.mint-btn {
  background: linear-gradient(134.69deg, #C63BC9 0%, #FFE94F 0.01%, #FFAF30 100%);
  color: #603813;
  border-radius: 42px;
}

.back-img {
  height: 100vh;
}

.nftUtiltiyBox {
  background: rgba(238, 123, 66, 0.12);
  border-radius: 20px;
  width: 100%;
}

.nftRareBox {
  background: rgba(237, 204, 56, 0.12);
  border-radius: 20px;
  width: 100%;
}

.nftLegendaryBox {
  background: rgba(59, 211, 43, 0.12);
  border-radius: 20px;
  width: 100%;
}

.truncate-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .trunc-wallet {
    width: 68vw;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .blackBox {
    width: unset;
  }

  .myBoredNfts {
    max-height: 440px;
  }
}

@media (max-width: 1024px) {
  .back-img {
    object-fit: cover;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.get-doger-btn {
  border: solid 3px transparent;
  background-image: linear-gradient(rgb(43 41 18), rgb(43 41 18)),
  linear-gradient(134.69deg, #C63BC9 0%, #FFE94F 0.01%, #FFAF30 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: #FFE94F;
  border-radius: 42px;
}

.warning-box {
  background: rgba(255, 62, 62, 0.28);
  border-radius: 8px;
  border-left: 6px solid #FF3E3E;
}

.bg-label {
  background-color: #FFFDE61A;
}

.disable-btn {
  background: rgba(68, 68, 68, 0.64);
  border-radius: 42px;
  color: #777777;
}

.new-nft-image {
  border: 5px solid #ffe94fb3;
}

.new-nft-label {
  color: #FFE94F;
  height: 35px;
  margin-top: -35px;
  z-index: 10;
  position: relative;
}

.nice-scroll::-webkit-scrollbar {
  width: 10px;
}

.nice-scroll::-webkit-scrollbar-track {
  background: #3D3D44;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb {
  background: #808087;
  border-radius: 5px;
}

.nice-scroll::-webkit-scrollbar-thumb:hover {
  background: #808087;
}

.nft-name {
  margin-top: 8px;
  margin-bottom: -10px;
}
</style>
