import { createStore } from 'vuex';
import modalStore from '@/os/modalStore';
import blockchainStore from '@/os/blockchain/store/blockchainStore';
import priceStore from '@/os/priceStore';
import frontStore from '@/apps/FrontPage/store';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    os: modalStore,
    blockchain: blockchainStore,
    prices: priceStore,
    front: frontStore,
  },
});
