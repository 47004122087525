import BigNumber from 'bignumber.js';
import { shortestRoute } from '@/os/APIs/contractAPI';

const AMM_DECIMALS = 8;
const BN_0 = new BigNumber(0);
const BN_1 = new BigNumber(1);
const BN_997 = new BigNumber(997);
const BN_1000 = new BigNumber(1000);

const FEE_RATE = new BigNumber(0.003);
const MAX_HOPS = 3;

export function getAmountOut({
  amountIn,
  reserveIn,
  reserveOut,
}) {
  if (!reserveIn.gt(BN_0) || !reserveOut.gt(BN_0)) {
    throw new Error('amount and reserve should be positive number');
  }
  if (!amountIn.gt(BN_0)) {
    return null;
  }
  const amountInWithFee = amountIn.times(BN_997);
  const amountOut = amountInWithFee
    .times(reserveOut)
    .idiv(reserveIn.times(BN_1000).plus(amountInWithFee));
  return amountOut;
}

export function getAmountIn({
  amountOut,
  reserveIn,
  reserveOut,
}) {
  if (!reserveIn.gt(BN_0) || !reserveOut.gt(BN_0)) {
    throw new Error('amount and reserve should be positive number');
  }
  if (!amountOut.gt(BN_0) || !amountOut.lt(reserveOut)) {
    return null;
  }
  const amountIn = reserveIn
    .times(amountOut)
    .times(BN_1000)
    .idiv(reserveOut.minus(amountOut).times(BN_997))
    .plus(BN_1);
  return amountIn;
}

function createTrade({
  route,
  amountIn,
  amountOut,
}) {
  const fee = amountIn.minus(route.reduce((acc) => acc.times(BN_1.minus(FEE_RATE)), amountIn));

  const price = amountIn.div(amountOut);
  const midPrice = route.reduce(
    (acc, item) => acc.times(item.reserveIn).div(item.reserveOut),
    BN_1,
  );
  const priceImpact = price
    .minus(midPrice)
    .div(price)
    .minus(fee.div(amountIn));

  return {
    route,
    amountIn,
    amountOut,
    fee,
    price,
    priceImpact,
  };
}

// function compareTrade(a, b) {
//   if (a.amountIn.comparedTo(b.amountIn) !== 0) {
//     // trade requires less input should come first
//     return a.amountIn.comparedTo(b.amountIn);
//   }
//   if (a.amountOut.comparedTo(b.amountOut) !== 0) {
//     // trade has more output should come first
//     return a.amountOut.comparedTo(b.amountOut) > 0 ? -1 : 1;
//   }
//   if (a.priceImpact.comparedTo(b.priceImpact) !== 0) {
//     // consider lowest slippage next, since these are less likely to fail
//     return a.priceImpact.comparedTo(b.priceImpact);
//   }
//   // finally consider the number of hops since each hop costs gas
//   return a.route.length - b.route.length;
// }

function getSwapInBestTrade({
  originalAssetOutName,
  amountIn,
  assetInName,
  pools,
}) {
  const shortRoute = shortestRoute(assetInName, originalAssetOutName);
  if (!shortRoute) return null;
  let bestRoute = null;

  let lastAmountIn = amountIn;
  let amountOut = 0;

  shortRoute.forEach((assetNameA, routeIndex) => {
    if (routeIndex >= shortRoute.length - 1) return;
    const assetNameB = shortRoute[routeIndex + 1];
    const pool = pools.find((p) => p.swapAssetNames.includes(assetNameA) && p.swapAssetNames.includes(assetNameB));

    const assetInIndex = pool.swapAssetNames.indexOf(assetNameA);
    const reserveIn = pool.reserves[assetInIndex];
    const currentAssetInName = pool.swapAssetNames[assetInIndex];
    const assetOutIndex = assetInIndex === 0 ? 1 : 0;
    const assetOutName = pool.swapAssetNames[assetOutIndex];
    const reserveOut = pool.reserves[assetOutIndex];

    amountOut = getAmountOut({
      amountIn: lastAmountIn,
      reserveIn,
      reserveOut,
    });
    if (!amountOut) {
      return;
    }

    lastAmountIn = amountOut;

    if (!bestRoute) bestRoute = [];

    bestRoute.push({
      assetInName: currentAssetInName,
      reserveIn,
      assetOutName,
      reserveOut,
    });
  });

  if (!bestRoute) return null;

  return createTrade({
    route: bestRoute,
    amountIn,
    amountOut,
  });
}

function getSwapOutBestTrade({
  // originalAmountOut,
  originalAssetInName,
  amountOut,
  assetOutName,
  pools,
  // maxHops,
  // route,
}) {
  const shortRoute = shortestRoute(originalAssetInName, assetOutName).reverse();
  if (!shortRoute) return null;

  let bestRoute = null;

  let lastAmountOut = amountOut;
  let amountIn = 0;

  shortRoute.forEach((assetNameA, routeIndex) => {
    if (routeIndex >= shortRoute.length - 1) return;
    const assetNameB = shortRoute[routeIndex + 1];
    const pool = pools.find((p) => p.swapAssetNames.includes(assetNameA) && p.swapAssetNames.includes(assetNameB));

    const assetInIndex = pool.swapAssetNames.indexOf(assetNameB);
    const reserveIn = pool.reserves[assetInIndex];
    const assetInName = pool.swapAssetNames[assetInIndex];
    const assetOutIndex = assetInIndex === 0 ? 1 : 0;
    const currentAssetOutName = pool.swapAssetNames[assetOutIndex];
    const reserveOut = pool.reserves[assetOutIndex];

    amountIn = getAmountIn({
      amountOut: lastAmountOut,
      reserveIn,
      reserveOut,
    });

    if (!amountIn) {
      return;
    }

    lastAmountOut = amountIn;

    if (!bestRoute) bestRoute = [];

    bestRoute.push({
      assetInName,
      reserveIn,
      assetOutName: currentAssetOutName,
      reserveOut,
    });
  });

  if (!bestRoute) return null;

  return createTrade({
    route: bestRoute.reverse(),
    amountIn,
    amountOut,
  });
}

export function checkSymbol(assetName) {
  return assetName === 'NEO' ? 'bNEO' : assetName;
}

/* eslint-disable no-param-reassign */
export function getSwapInData({
  amountIn,
  assetInName,
  assetOutName,
  pools,
  tolerance,
}) {
  assetInName = checkSymbol(assetInName);
  assetOutName = checkSymbol(assetOutName);
  const trade = getSwapInBestTrade({
    originalAmountIn: amountIn,
    originalAssetOutName: assetOutName,
    amountIn,
    assetInName,
    pools,
    maxHops: MAX_HOPS,
    route: [],
  });

  if (!trade) return null;

  const {
    route,
    amountOut,
    fee,
    price,
    priceImpact,
  } = trade;
  const amountOutMin = amountOut.times(BN_1.minus(tolerance)).dp(0);
  const priceInverse = BN_1.div(price);

  return {
    route,
    amountOut,
    amountOutMin,
    fee,
    price,
    priceInverse,
    priceImpact,
  };
}

export function getSwapOutData({
  amountOut,
  assetInName,
  assetOutName,
  pools,
  tolerance,
}) {
  assetInName = checkSymbol(assetInName);
  assetOutName = checkSymbol(assetOutName);
  const trade = getSwapOutBestTrade({
    originalAmountOut: amountOut,
    originalAssetInName: assetInName,
    amountOut,
    assetOutName,
    pools,
    maxHops: MAX_HOPS,
    route: [],
  });

  if (!trade) return null;

  const {
    route,
    amountIn,
    fee,
    price,
    priceImpact,
  } = trade;
  const amountInMax = amountIn.times(BN_1.plus(tolerance)).dp(0);
  const priceInverse = BN_1.div(price);

  return {
    route,
    amountIn,
    amountInMax,
    fee,
    price,
    priceInverse,
    priceImpact,
  };
}

export function getAddLiquidityData({
  amountA,
  reserveA,
  reserveB,
  totalSupply,
  tolerance,
}) {
  if (!reserveA.gt(BN_0) || !reserveB.gt(BN_0)) {
    throw new Error('amount and reserve should be positive number');
  }
  if (!amountA.gt(BN_0)) {
    return null;
  }
  const amountAMin = amountA.times(BN_1.minus(tolerance)).dp(0);

  const amountB = amountA.times(reserveB).idiv(reserveA);
  const amountBMin = amountB.times(BN_1.minus(tolerance)).dp(0);

  const liquidityA = amountA.times(totalSupply).idiv(reserveA);
  const liquidityB = amountB.times(totalSupply).idiv(reserveB);
  const liquidity = liquidityA.lt(liquidityB) ? liquidityA : liquidityB;

  const share = liquidity.div(liquidity.plus(totalSupply));
  const price = amountB.div(amountA);
  const priceInverse = BN_1.div(price);

  return {
    amountAMin,
    amountB,
    amountBMin,
    liquidity,
    share,
    price,
    priceInverse,
  };
}

export function getRemoveLiquidityData({
  liquidity,
  reserveA,
  reserveB,
  totalSupply,
  tolerance,
}) {
  if (!reserveA.gt(BN_0) || !reserveB.gt(BN_0)) {
    throw new Error('amount and reserve should be positive number');
  }
  if (!liquidity.gt(BN_0)) {
    return null;
  }
  const amountA = liquidity.times(reserveA).idiv(totalSupply);
  const amountAMin = amountA.times(BN_1.minus(tolerance)).dp(0);

  const amountB = liquidity.times(reserveB).idiv(totalSupply);
  const amountBMin = amountB.times(BN_1.minus(tolerance)).dp(0);

  const share = liquidity.div(totalSupply);
  const price = amountB.div(amountA);
  const priceInverse = BN_1.div(price);

  return {
    amountA,
    amountAMin,
    amountB,
    amountBMin,
    share,
    price,
    priceInverse,
  };
}

const PERP_FEE_RATE = new BigNumber(0.001);
const PERP_MIN_MARGIN_RATIO = new BigNumber(0.2);

const PERP_MINIMAL_AMOUNT = BN_1.shiftedBy(-AMM_DECIMALS);

export function getDx(x, y, dy) {
  if (!dy.gt(y.negated())) {
    return null;
  }
  return x
    .times(y)
    .div(y.plus(dy))
    .minus(x);
}

export function getDy(x, y, dx) {
  if (!dx.gt(x.negated())) {
    return null;
  }
  return x
    .times(y)
    .div(x.plus(dx))
    .minus(y);
}

export function getPerpTradeLimit({
  x0,
  y0,
  positionSide,
  size,
  margin,
  entryValue,
  entryFundingLoss,
  availableMargin,
  currentFundingLoss,
  tradeSide,
}) {
  if (tradeSide === positionSide || size.isZero()) {
    if (!availableMargin.gte(BN_0)) {
      return BN_0;
    }
    const dx = (tradeSide === 1 ? availableMargin : availableMargin.negated()).div(
      PERP_MIN_MARGIN_RATIO.plus(PERP_FEE_RATE),
    );
    const dy = getDy(x0, y0, dx);
    if (!dy) {
      return y0.minus(PERP_MINIMAL_AMOUNT);
    }
    return dy.abs();
  }

  const dy0 = tradeSide === 1 ? size.negated() : size;
  const dx0 = getDx(x0, y0, dy0);
  if (!dx0) {
    return y0.minus(PERP_MINIMAL_AMOUNT);
  }
  const x1 = x0.plus(dx0);
  const y1 = y0.plus(dy0);

  const marginLeft = margin
    .minus(dx0.abs().times(PERP_FEE_RATE))
    .plus(positionSide === 1 ? dx0.abs().minus(entryValue) : entryValue.minus(dx0.abs()))
    .plus(
      currentFundingLoss
        .minus(entryFundingLoss.div(positionSide === 1 ? size : size.negated()))
        .negated()
        .times(dy0),
    );

  if (!marginLeft.gte(BN_0)) {
    return BN_0;
  }

  const dx1 = (tradeSide === 1 ? marginLeft : marginLeft.negated()).div(
    PERP_MIN_MARGIN_RATIO.plus(PERP_FEE_RATE),
  );
  const dy1 = getDy(x1, y1, dx1);
  if (!dy1) {
    return y0.minus(PERP_MINIMAL_AMOUNT);
  }

  return dy0.plus(dy1).abs();
}

export function getPerpTradeData({
  x0,
  y0,
  positionSide,
  size,
  margin,
  entryValue,
  entryFundingLoss,
  currentFundingLoss,
  tradeSide,
  amount,
  tolerance,
}) {
  const dy = tradeSide === 1 ? amount.negated() : amount;
  const dx = getDx(x0, y0, dy);
  if (!dx) {
    return null;
  }

  const fee = dx.abs().times(PERP_FEE_RATE);
  const price = dx.div(dy).negated();
  const priceLimit = price.times(BN_1.plus(tradeSide === 1 ? tolerance : tolerance.negated()));
  const midPrice = x0.div(y0);
  const priceImpact = price.minus(midPrice).div(midPrice);

  let updatedEntryValue;
  let updatedMargin;
  if (positionSide === tradeSide || size.isZero()) {
    // trade on same side with position
    updatedEntryValue = entryValue.plus(dx.abs());
    updatedMargin = margin.minus(fee);
  } else if (amount.lte(size)) {
    // trade on opposite side with position, not excceeding current size
    updatedEntryValue = entryValue.minus(entryValue.times(amount.div(size)));
    updatedMargin = margin
      .minus(fee)
      .plus(
        positionSide === 1
          ? dx.abs().minus(entryValue.minus(updatedEntryValue))
          : entryValue.minus(updatedEntryValue).minus(dx.abs()),
      )
      .plus(
        currentFundingLoss
          .minus(entryFundingLoss.div(positionSide === 1 ? size : size.negated()))
          .negated()
          .times(dy),
      );
  } else {
    // trade on opposite side with position, excceeding current size
    const dy0 = tradeSide === 1 ? size.negated() : size;
    const dx0 = getDx(x0, y0, dy0);
    if (!dx0) {
      return null;
    }
    const x1 = x0.plus(dx0);
    const y1 = y0.plus(dy0);

    const marginLeft = margin
      .minus(dx0.abs().times(PERP_FEE_RATE))
      .plus(positionSide === 1 ? dx0.abs().minus(entryValue) : entryValue.minus(dx0.abs()))
      .plus(
        currentFundingLoss
          .minus(entryFundingLoss.div(positionSide === 1 ? size : size.negated()))
          .negated()
          .times(dy0),
      );

    const amount1 = amount.minus(size);
    const dy1 = tradeSide === 1 ? amount1.negated() : amount1;
    const dx1 = getDx(x1, y1, dy1);
    if (!dx1) {
      return null;
    }

    updatedEntryValue = dx1.abs();
    updatedMargin = marginLeft.minus(dx1.abs().times(PERP_FEE_RATE));
  }

  return {
    fee,
    price,
    priceLimit,
    priceImpact,
    updatedEntryValue,
    updatedMargin,
  };
}
