import walletAPI from '@/os/APIs/walletAPI';
import contractAPI from '@/os/APIs/contractAPI';
import { tokenNameToHash } from '@/os/utils/tokens';
import InvokeConfig from '@/os/utils/invoke';

function getSwapContractsForRoute(routes) {
  const allowedContracts = [
    contractAPI.getContractByName('swap').hash,
    contractAPI.getContractByName('swapWhitelist').hash,
    contractAPI.getContractByName('swapFactory').hash,
  ];

  const routeHashes = routes.map((r) => r.value);

  routeHashes.forEach((routeHash, index) => {
    allowedContracts.push(routeHash);

    if (index >= routes.length - 1) return;
    const pool = contractAPI.getPoolByTokenHashes(routeHash, routeHashes[index + 1]);
    allowedContracts.push(pool.hash);
  });

  return allowedContracts;
}

function swapIn(routes, amountIn, amountOutMin, deadline) {
  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('swap').hash,
      operation: 'swapTokenInForTokenOut',
      args: [
        {
          type: 'Address',
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Integer',
          value: amountIn,
        },
        {
          type: 'Integer',
          value: amountOutMin,
        },
        {
          type: 'Array',
          value: routes,
        },
        {
          type: 'Integer', // deadline
          value: deadline,
        },
      ],
    },
  ];

  const allowedContracts = getSwapContractsForRoute(routes);

  return new InvokeConfig(invocations, allowedContracts);
}

function swapOut(routes, amountOut, amountInMax, deadline) {
  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('swap').hash,
      operation: 'swapTokenOutForTokenIn',
      args: [
        {
          type: 'Address',
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Integer',
          value: amountOut,
        },
        {
          type: 'Integer',
          value: amountInMax,
        },
        {
          type: 'Array',
          value: routes,
        },
        {
          type: 'Integer', // deadline
          value: deadline,
        },
      ],
    },
  ];

  const allowedContracts = getSwapContractsForRoute(routes);

  return new InvokeConfig(invocations, allowedContracts);
}

function addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, deadline) {
  const tokenAHash = tokenNameToHash(tokenA);
  const tokenBHash = tokenNameToHash(tokenB);

  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('swap').hash,
      operation: 'addLiquidity',
      args: [
        {
          type: 'Address', // sender
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Hash160', // tokenA
          value: tokenAHash,
        },
        {
          type: 'Hash160', // tokenB
          value: tokenBHash,
        },
        {
          type: 'Integer', // amountADesired
          value: amountADesired,
        },
        {
          type: 'Integer', // amountBDesired
          value: amountBDesired,
        },
        {
          type: 'Integer', // amountAMin
          value: amountAMin,
        },
        {
          type: 'Integer', // amountBMin
          value: amountBMin,
        },
        {
          type: 'Integer', // deadLine
          value: deadline,
        },
      ],
    },
  ];

  const pool = contractAPI.getPoolByTokenHashes(tokenAHash, tokenBHash);
  const allowedContracts = [
    contractAPI.getContractByName('swap').hash,
    pool.hash,
    tokenAHash,
    tokenBHash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

function removeLiquidity(tokenA, tokenB, liquidityToRemove, amountAMin, amountBMin, deadline) {
  const tokenAHash = tokenNameToHash(tokenA);
  const tokenBHash = tokenNameToHash(tokenB);

  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('swap').hash,
      operation: 'removeLiquidity',
      args: [
        {
          type: 'Address', // sender
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Hash160', // tokenA
          value: tokenAHash,
        },
        {
          type: 'Hash160', // tokenB
          value: tokenBHash,
        },
        {
          type: 'Integer', // liquidity - The amount of liquidity Token removed
          value: liquidityToRemove,
        },
        {
          type: 'Integer', // amountAMin
          value: amountAMin,
        },
        {
          type: 'Integer', // amountBMin
          value: amountBMin,
        },
        {
          type: 'Integer', // deadLine
          value: deadline,
        },
      ],
    },
  ];

  const pool = contractAPI.getPoolByTokenHashes(tokenAHash, tokenBHash);

  const allowedContracts = [
    contractAPI.getContractByName('swap').hash,
    pool.hash,
    tokenAHash,
    tokenBHash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

export default {
  swapIn,
  swapOut,
  addLiquidity,
  removeLiquidity,
};
