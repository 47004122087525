import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/apps/FrontPage/App.vue';
import HomeRouter from '@/apps/FrontPage/router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: { name: 'Landing' },
    children: HomeRouter,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
