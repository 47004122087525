import BigNumber from 'bignumber.js';
import Bn from 'bn.js';

export function castToRpcHash(hash) {
  return {
    type: 'Hash160',
    value: hash,
  };
}

export function isValidHex(hex) {
  return typeof hex === 'string' && /^(0[xX])?([0-9A-Fa-f]{2})*$/.test(hex);
}

export function toStandardHex(hex) {
  if (!isValidHex(hex)) {
    throw new Error(`input param is not a valid hex string: "${hex}"`);
  }
  return hex.replace(/0[xX]/, '').toLowerCase();
}

export function integerToDecimal(integer, unit) {
  if (new BigNumber(integer).isNaN()) {
    return null;
  }
  return new BigNumber(integer).shiftedBy(-unit).toFixed();
}

export function decimalToInteger(decimal, unit) {
  if (new BigNumber(decimal).isNaN()) {
    return null;
  }
  return new BigNumber(decimal)
    .shiftedBy(unit)
    .dp(0)
    .toFixed();
}

export function bytesToHex(bytes) {
  return Buffer.from(bytes).toString('hex');
}

export function hexToBytes(hex) {
  return Buffer.from(hex, 'hex');
}

export function reverseHex(hex) {
  return bytesToHex(hexToBytes(hex).reverse());
}

export function hexToInteger(hex) {
  const bytes = hexToBytes(hex).reverse();
  if (bytes.length === 0) {
    return '0';
  }
  const bn = new Bn(bytes).fromTwos(bytes.length * 8);
  return bn.toString();
}

export default {
  castToRpcHash,
  isValidHex,
  toStandardHex,
  integerToDecimal,
  decimalToInteger,
  bytesToHex,
  hexToBytes,
  reverseHex,
};
