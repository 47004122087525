<template>
  <div class="inline-flex relative">
    <div
      class="outline-none w-full rounded-md pl-4 cursor-pointer font-poppins"
      @click="toggleTokenSelector()"
    >
      <div v-if="tagValue !== null" class="flex items-center">
        <img
          v-if="tagValue !== null"
          class="w-6 h-6"
          :src="tokenImage(tagValue.symbol)"
        >
        <div class="flex-grow ml-2 color-label">{{ tagValue.symbol }}</div>
        <i
          class="fas fa-caret-down ml-3 color-label"
        />
      </div>
      <div v-else>
        Select Token
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="selectorIsVisible"
        class="bg-gray-800 rounded-lg shadow-card absolute right-0 my-1 z-10 left-0 overflow-auto nice-scroll"
        style="max-height: 182px;"
        :style="selectorStyle"
        ref="selector"
        v-click-outside="hideSelector"
      >
        <div
          v-for="(item, key) in tokens"
          :key="key"
          @click="selectOpt(item)"
          class="token-menu-item flex items-center border-b-2 border-gray-900 last:border-b-0 px-2 py-2 cursor-pointer transition duration-100 bg-gray-800"
        >
          <img
            :src="tokenImage(item.symbol)"
            class="w-6 h-6"
            :alt="item.symbol"
          >
          <div class="text-sm flex-grow ml-2">
            {{ item.symbol }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import walletAPI from '@/os/APIs/walletAPI';
import tokensData from '@/os/values/tokens';

export default {
  name: 'NudesSelect',
  props: {
    modelValue: {},
    placeholderValue: {},
  },
  emits: ['update:token'],
  setup(props, { emit }) {
    const selectorIsVisible = ref(false);
    const tagValue = ref(null);

    function toggleTokenSelector(show = null) {
      selectorIsVisible.value = (show === null) ? !selectorIsVisible.value : show;
    }

    function getTokenBalance(token) {
      const tokenDetails = walletAPI.state.walletData.value.balances.find((tItem) => tItem.symbol === token);
      if (tokenDetails === undefined) return 0;
      return Number(tokenDetails.amount);
    }

    function balanceSorting(a, b) {
      if (a.balanceInUserCurreny < b.balanceInUserCurreny) return 1;
      if (a.balanceInUserCurreny > b.balanceInUserCurreny) return -1;
      return 0;
    }

    const tokens = computed(() => {
      let filteredTokens = tokensData;

      filteredTokens = Object.values(filteredTokens).filter((item) => item?.isSwappable).map((token) => ({
        symbol: token.symbol,
        balance: getTokenBalance(token.symbol),
      }));

      filteredTokens = filteredTokens.sort(balanceSorting);

      return filteredTokens;
    });

    function tokenImage(token) {
      return `/img/tokens/circle/${token}.svg`;
    }

    function hideSelector() {
      selectorIsVisible.value = false;
    }

    function emitTag() {
      emit('update:token', tagValue.value.symbol);
      selectorIsVisible.value = false;
    }

    function selectOpt(item) {
      tagValue.value = item;
      emitTag();
    }

    const selector = ref();
    const selectorStyle = ref({ top: '100%' });

    return {
      tokens,
      selectorIsVisible,
      toggleTokenSelector,
      hideSelector,
      selectOpt,
      selector,
      selectorStyle,
      tagValue,
      emitTag,
      tokenImage,
    };
  },
};

</script>

<style
  lang="scss"
  scoped
>

.token-menu-item:hover {
  background: #473ECB;
}

.token-menu-item:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.token-menu-item:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 100ms;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.nice-scroll {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #3D3D44;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #808087;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #808087;
  }
}

.bg-selectbox {
  background: rgba(17, 17, 17, 0.64);
}

.color-label {
  color: #FFFDE6;
}
</style>
