import { computed } from 'vue';
import store from '@/store';

const state = {
  activeModal: computed(() => store.state.os.activeModal),
};

function triggerModal(modal = '') {
  store.commit('os/setActiveModal', modal);
}

export default {
  triggerModal,
  state,
};
