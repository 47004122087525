import Neon, { sc, wallet } from '@cityofzion/neon-js';
import store from '@/store';
import { hexToInteger } from '@/os/utils/convert';
import contractAPI from '@/os/APIs/contractAPI';
import rpcAPI from '@/os/APIs/rpcAPI';
import pools from '@/os/values/pools';

function parseStackInteger(param) {
  const {
    type,
    value,
  } = param || {};
  let ret = null;
  if (type === 'Integer') {
    ret = value;
  } else if (type === 'ByteArray') {
    ret = hexToInteger(value);
  }
  return ret;
}

function createBalanceOfTransaction(poolName) {
  const pool = contractAPI.getContractByName(poolName);
  const { address } = store.state.blockchain.wallet;
  return sc.createScript({
    scriptHash: pool.hash,
    operation: 'balanceOf',
    args: [
      Neon.create.contractParam('Hash160', wallet.getScriptHashFromAddress(address)),
    ],
  });
}

function createTotalSupplyTransaction(poolName) {
  const pool = contractAPI.getContractByName(poolName);

  return sc.createScript({
    scriptHash: pool.hash,
    operation: 'totalSupply',
    args: [],
  });
}

function createReserveTransaction(poolName) {
  const pool = contractAPI.getContractByName(poolName);

  return sc.createScript({
    scriptHash: pool.hash,
    operation: 'getReserves',
    args: [],
  });
}

export async function getPoolStates() {
  if (!store.state.blockchain.conditions.walletConnected) return {};

  const rawScripts = [];

  Object.keys(pools).forEach((pool) => {
    rawScripts.push(createBalanceOfTransaction(pool));
    rawScripts.push(createTotalSupplyTransaction(pool));
    rawScripts.push(createReserveTransaction(pool));
  });

  const rawScript = Buffer.from(rawScripts.join(''), 'hex').toString('base64');

  const invoke = {
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [rawScript],
  };

  const response = await rpcAPI.rawRpc(invoke);
  const resultStack = response.result.stack;

  const reserves = {};
  let stackIndex = 0;

  Object.keys(pools).forEach((poolName) => {
    const rawBalance = resultStack[stackIndex * 3];
    const rawTotalSupply = resultStack[stackIndex * 3 + 1];
    const rawReserve = resultStack[stackIndex * 3 + 2];

    reserves[poolName] = {
      liquidity: parseStackInteger(rawBalance),
      totalSupply: parseStackInteger(rawTotalSupply),
      reserveA: parseStackInteger(rawReserve.value[0]),
      reserveB: parseStackInteger(rawReserve.value[1]),
    };

    stackIndex += 1;
  });

  return reserves;
}

export default {
  getPoolStates,
};
