import BigNumber from 'bignumber.js';

export function getInteger(number) {
  return new BigNumber(number).integerValue(BigNumber.ROUND_FLOOR);
}

export function getFractional(number, decimals = 8) {
  return new BigNumber(number)
    .dp(decimals)
    .toFixed(decimals)
    .split('.')[1];
}

export function amountToBigInt(amount, decimals) {
  return amount * (10 ** decimals);
}

export function bigIntToAmount(amount, decimals) {
  return amount / (10 ** decimals);
}

function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

export default {
  getInteger,
  getFractional,
  amountToBigInt,
  bigIntToAmount,
  sleep,
};
