/* eslint-disable */
import { computed, ref } from 'vue';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import poolData from '@/os/values/pools';
import tokens from '@/os/values/tokens';
import { getSwapInData, getSwapOutData, checkSymbol } from '@/os/utils/calculators';
import invoke from './utils/invoke';
import { getPoolStates } from './utils/reserves';

const api = axios.create();

const allNfts = ref([]);
const myCollections = ref([]);

const rareNft = ref([]);
const rareNftSupply = ref({
  total: 0,
  mint: 0,
});
const ultraNft = ref([]);
const ultraNftSupply = ref({
  total: 0,
  mint: 0,
});
const legendNft = ref([]);
const legendNftSupply = ref({
  total: 0,
  mint: 0,
});
const price = ref(0);
const totalNft = ref();
const nftCount = ref();
const tokenPrice = ref(0);
const inputToken = ref(null);
const outputToken = ref('DOGER');
const amount = ref('');
const poolStates = ref({});
const slippagePerMille = ref(5);
const isSwapIn = ref(true);

async function updatePoolStates() {
  poolStates.value = await getPoolStates();
}

function getTokenDecimals(token) {
  return tokens[token].decimals;
}

const swapData = computed(() => {
  // The tokenAmount variable needs to be on top to make Vue reactive.
  const tokenAmount = amount.value === '' ? 0 : amount.value;

  if (!inputToken.value || !outputToken.value) {
    return null;
  }

  if (Object.keys(poolStates.value).length === 0) {
    updatePoolStates();
    return null;
  }
  const pools = Object.values(poolData).map((pool) => ({
    swapAssetNames: [pool.tokens[0]?.symbol, pool.tokens[1]?.symbol],
    reserves: [
      BigNumber(poolStates.value[pool.symbol].reserveA),
      BigNumber(poolStates.value[pool.symbol].reserveB),
    ],
  }));

  const tokenName = isSwapIn.value ? inputToken.value : outputToken.value;
  const tokenInDecimals = getTokenDecimals(checkSymbol(tokenName));
  const amountBn = (new BigNumber(tokenAmount)).shiftedBy(tokenInDecimals);
  const toleranceBn = (new BigNumber(slippagePerMille.value)).shiftedBy(-3);

  if (isSwapIn.value) {
    return getSwapInData({
      amountIn: amountBn,
      assetInName: inputToken.value,
      assetOutName: outputToken.value,
      pools,
      tolerance: toleranceBn,
    });
  }

  return getSwapOutData({
    amountOut: amountBn,
    assetInName: inputToken.value,
    assetOutName: outputToken.value,
    pools,
    tolerance: toleranceBn,
  });
});

function reset() {
  rareNftSupply.value.total = 0;
  rareNftSupply.value.mint = 0;
  ultraNftSupply.value.total = 0;
  ultraNftSupply.value.mint = 0;
  legendNftSupply.value.total = 0;
  legendNftSupply.value.mint = 0;
}

async function init() {
  reset();
  const nftList = await invoke.getElements();
  allNfts.value = nftList;
  rareNft.value = nftList.filter((item) => item?.nft?.attributes[0]?.value === 'Basic');
  rareNft.value.map((item) => {
    rareNftSupply.value.total += item.totalAmount;
    rareNftSupply.value.mint += item.mintAmount;
  });
  ultraNft.value = nftList.filter((item) => item?.nft?.attributes[0]?.value === 'Rare');
  ultraNft.value.map((item) => {
    ultraNftSupply.value.total += item.totalAmount;
    ultraNftSupply.value.mint += item.mintAmount;
  });
  legendNft.value = nftList.filter((item) => item?.nft?.attributes[0]?.value === 'Legendary');
  legendNft.value.map((item) => {
    legendNftSupply.value.total += item.totalAmount;
    legendNftSupply.value.mint += item.mintAmount;
  });
  price.value = await invoke.getPrice();
  totalNft.value = await invoke.getTotalNfts();
  nftCount.value = await invoke.getNftsCount();
  const flamTokenPrices = await api.get('https://api.flamingo.finance/token-info/prices');
  flamTokenPrices.data.map((item) => {
    if (item.symbol === 'DOGER') tokenPrice.value = item.usd_price;
  });
  console.log(nftList, price.value);
}

async function getCollections() {
  const res = await invoke.getTokensOf();
  myCollections.value = [];
  res.map((item) => {
    const tmp = allNfts.value.find((nft) => nft.settings.id_name === item);
    myCollections.value.push(tmp);
  });
}

const inputAmount = computed({
  get() {
    if (isSwapIn.value) return amount.value;
    if (!swapData.value || !swapData.value.amountIn) return null;

    const value = BigNumber(swapData.value.amountIn);
    const tokenInDecimals = getTokenDecimals(checkSymbol(inputToken.value));
    return value.shiftedBy(-tokenInDecimals);
  },
  set(value) {
    isSwapIn.value = true;
    amount.value = value;
  },
});

const outputAmount = computed({
  get() {
    if (!isSwapIn.value) return amount.value;
    if (!swapData.value || !swapData.value.amountOut) return null;

    const value = BigNumber(swapData.value.amountOut);
    const tokenInDecimals = getTokenDecimals(checkSymbol(outputToken.value));
    return value.shiftedBy(-tokenInDecimals);
  },
  set(value) {
    isSwapIn.value = false;
    amount.value = value;
  },
});

export default {
  rareNft,
  rareNftSupply,
  ultraNft,
  ultraNftSupply,
  legendNft,
  legendNftSupply,
  totalNft,
  price,
  tokenPrice,
  init,
  swapData,
  inputToken,
  outputToken,
  amount,
  inputAmount,
  outputAmount,
  isSwapIn,
  getCollections,
  myCollections,
  allNfts,
  nftCount,
};
