import Home from '@/apps/FrontPage/pages/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Home,
    meta: { title: 'Nudes Army - Home' },
  },
];

export default routes;
