import {
  CHAIN_ID_BSC,
  CHAIN_ID_ETHEREUM,
  CHAIN_ID_NEO,
  CHAIN_ID_ONTOLOGY,
} from '@/os/values/chains';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

const testnetTokens = {
  // ============ Neo Assets ============ //
  FLM: {
    symbol: 'FLM',
    walletSymbol: 'FLM',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'FLM',
    decimals: 8,
    hash: '0x1415ab3b409a95555b77bc4ab6a7d9d7be0eddbd',
    isSwappable: true,
  },
  FLUND: {
    symbol: 'FLUND',
    walletSymbol: 'FLUND',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'FLUND',
    decimals: 8,
    hash: '0x51a7d6054117e3be2e30ec13f1c20c87fc456118',
    isSwappable: false,
  },
  NUDES: {
    symbol: 'NUDES',
    walletSymbol: 'NUDES',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NUDES',
    decimals: 8,
    hash: '0x340720c7107ef5721e44ed2ea8e314cce5c130fa',
    isSwappable: true,
    canBeWrappedTo: 'bNUDES',
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'bNUDES',
  },
  CANDY: {
    symbol: 'CANDY',
    walletSymbol: 'CANDY',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'CANDY',
    decimals: 9,
    hash: '0xa90c11b6f71cee327bf9f62060641e5ad22f57e6',
    isSwappable: true,
  },
  DOGER: {
    symbol: 'DOGER',
    walletSymbol: 'DOGER',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'DOGER',
    decimals: 8,
    hash: '0xfb8e6f24352f858ca421f56ddd6c807d611bd8f4',
    isSwappable: true,
  },
  DOGEF: {
    symbol: 'DOGEF',
    walletSymbol: 'DOGEF',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'DOGEF',
    decimals: 8,
    hash: '0xf91230687638306d9f1fd2422f89445023d5caab',
    isSwappable: true,
  },
  NEO: {
    symbol: 'NEO',
    walletSymbol: 'NEO',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NEO',
    canBeWrappedTo: 'bNEO',
    decimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    isSwappable: true,
  },
  GAS: {
    symbol: 'GAS',
    walletSymbol: 'GAS',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'GAS',
    decimals: 8,
    hash: '0xd2a4cff31913016155e38e474a2c06d08be276cf',
    isSwappable: true,
  },
  bNEO: {
    symbol: 'bNEO',
    walletSymbol: 'bNEO',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NEO',
    canBeUnwrappedTo: 'NEO',
    decimals: 8,
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    isSwappable: true,
  },
  LRB: {
    symbol: 'LRB',
    walletSymbol: 'LRB',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'LRB',
    decimals: 8,
    hash: '0x569ab5968f695a13947a2393b3297d520d951243',
    isSwappable: true,
  },
  USDL: {
    symbol: 'USDL',
    walletSymbol: 'USDL',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'USDL',
    decimals: 8,
    hash: '0x118ee095a408e1db2a309a67b01030010e4191d8',
    isSwappable: true,
  },
  SWTH: {
    symbol: 'SWTH',
    walletSymbol: 'SWTH',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'SWTH',
    canBeUnwrappedTo: 'SWTH',
    decimals: 8,
    hash: '0x285b332bc0323bc334987bd4735fb39cc3269e20',
    isSwappable: true,
  },
  GM: {
    symbol: 'GM',
    walletSymbol: 'GM',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'GM',
    decimals: 8,
    hash: '0x047dbe376096643d7a93609388cde7f84ec28c52',
    isSwappable: true,
  },
  fUSDT: {
    symbol: 'fUSDT',
    walletSymbol: 'fUSDT',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'USDT',
    decimals: 6,
    canBeUnwrappedTo: 'USDT',
    hash: '0x83c442b5dc4ee0ed0e5249352fa7c75f65d6bfd6',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'USDT',
  },
  fWETH: {
    symbol: 'fWETH',
    walletSymbol: 'fWETH',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'ETH',
    decimals: 18,
    canBeUnwrappedTo: 'WETH',
    hash: '0x38a2aace91f92b979207c0dd50a857c117d4785b',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'WETH',
  },
  fWBTC: {
    symbol: 'fWBTC',
    walletSymbol: 'fWBTC',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'BTC',
    canBeUnwrappedTo: 'WBTC',
    decimals: 8,
    hash: '0xad97a439b4a035184d1ab46a07ee75687f541237',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'WBTC',
  },
  pONT: {
    symbol: 'pONT',
    walletSymbol: 'pONT',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'ONT',
    canBeUnwrappedTo: 'ONTd',
    decimals: 9,
    hash: '74ba424c768dbbb64ce02f7786137cdd4a5dc27b',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'ONTd',
  },
  fCAKE: {
    symbol: 'fCAKE',
    walletSymbol: 'fCAKE',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'CAKE',
    canBeUnwrappedTo: 'CAKE',
    decimals: 18,
    hash: '0xa2c70a5d40304785362a7df66b1f88826039b08e',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'WBTC',
  },
  // ============ Ethereum Assets ============ //
  WETH: {
    symbol: 'WETH',
    walletSymbol: 'WETH',
    unwrappedSymbol: 'ETH',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 18,
    hash: '0xb603cea165119701b58d56d10d2060fbfb3efad8',
    canBeWrappedTo: 'fWETH',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'FWETH',
    wrapperPolyAsset: 'xWETH',
    incomeBridgeHash: '0x9083DDD0e9B8f7DD2330358102E9F3c9d0570B33',
  },
  WBTC: {
    symbol: 'WBTC',
    walletSymbol: 'WBTC',
    unwrappedSymbol: 'BTC',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 8,
    hash: '0x557563dc4ed3fd256eba55b9622f53331ab97c2f',
    canBeWrappedTo: 'fWBTC',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'fWBTC',
    wrapperPolyAsset: 'xWBTC',
    incomeBridgeHash: '0x41F539383e92b1A5662e7b1a9AbE229612638EE7',
  },
  USDT: {
    symbol: 'USDT',
    walletSymbol: 'USDT',
    unwrappedSymbol: 'USDT',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 6,
    hash: '0xad3f96ae966ad60347f31845b7e4b333104c52fb',
    canBeWrappedTo: 'fUSDT',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'fUSDT',
    wrapperPolyAsset: 'xUSDT',
    incomeBridgeHash: '0xDC6cB70297419176c1B6297623E5F12Dce22CFf9',
  },
  xWETH: {
    symbol: 'xWETH',
    walletSymbol: 'xWETH',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0xd38fc89febb12e5d32faa72e07d486a268b9c63a',
    decimals: 18,
    incomeV2: true,
    incomeType: 'xToken',
  },
  xWBTC: {
    symbol: 'xWBTC',
    walletSymbol: 'xWBTC',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0x447b931cc604a30dc16c49002f35a7aab7888b78',
    decimals: 8,
    incomeV2: true,
    incomeType: 'xToken',
  },
  xUSDT: {
    symbol: 'xUSDT',
    walletSymbol: 'xUSDT',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0xf15b5430321f4e51f5f534cedada7fbb78fa6680',
    decimals: 6,
    incomeV2: true,
    incomeType: 'xToken',
  },
  // ============ Ontology Assets ============ //
  ONTd: {
    symbol: 'ONTd',
    walletSymbol: 'ONTd',
    chainId: CHAIN_ID_ONTOLOGY,
    hash: '869951e3397550e800d5faf579857cdb637a0051',
    canBeWrappedTo: 'pONT',
    decimals: 9,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'pONT',
  },
  // ============ BSC Assets ============ //
  CAKE: {
    symbol: 'CAKE',
    walletSymbol: 'CAKE',
    chainId: CHAIN_ID_BSC,
    hash: '0x778140d3A1e0024Be11e723a8af2C4d521feBA4f',
    canBeWrappedTo: 'fCAKE',
    decimals: 18,
    wrapperMethods: ['deposit', 'unlock'],
    wrapperTarget: 'fCAKE',
    wrapperPolyAsset: 'xfCAKE',
    incomeBridgeHash: '0x64A647dC1634d164896D7146f7Ad314665DFeB18',
  },
  xfCAKE: {
    symbol: 'xfCAKE',
    walletSymbol: 'xfCAKE',
    chainId: CHAIN_ID_BSC,
    hash: '0x64A647dC1634d164896D7146f7Ad314665DFeB18',
    decimals: 18,
  },
  bNUDES: {
    symbol: 'bNUDES',
    walletSymbol: 'NUDES',
    unwrappedSymbol: 'NUDES',
    chainId: CHAIN_ID_BSC,
    hash: '0xeA5143686D0edb60dcE657CeDA8367a92a2Bff58',
    canBeUnwrappedTo: 'NUDES',
    decimals: 8,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'NUDES',
  },
};

const mainnetTokens = {
  // ============ Neo Assets ============ //
  FLM: {
    symbol: 'FLM',
    walletSymbol: 'FLM',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'FLM',
    decimals: 8,
    hash: '0xf0151f528127558851b39c2cd8aa47da7418ab28',
    isSwappable: true,
  },
  FLUND: {
    symbol: 'FLUND',
    walletSymbol: 'FLUND',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'FLUND',
    decimals: 8,
    hash: '0xa9603a59e21d29e37ac39cf1b5f5abf5006b22a3',
    isSwappable: false,
  },
  NUDES: {
    symbol: 'NUDES',
    walletSymbol: 'NUDES',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NUDES',
    decimals: 8,
    hash: '0x340720c7107ef5721e44ed2ea8e314cce5c130fa',
    isSwappable: true,
    volatile: true,
    canBeWrappedTo: 'bNUDES',
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'bNUDES',
  },
  NEO: {
    symbol: 'NEO',
    walletSymbol: 'NEO',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NEO',
    canBeWrappedTo: 'bNEO',
    decimals: 0,
    hash: '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    isSwappable: true,
  },
  GAS: {
    symbol: 'GAS',
    walletSymbol: 'GAS',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'GAS',
    decimals: 8,
    hash: '0xd2a4cff31913016155e38e474a2c06d08be276cf',
    isSwappable: true,
  },
  bNEO: {
    symbol: 'bNEO',
    walletSymbol: 'bNEO',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'NEO',
    canBeUnwrappedTo: 'NEO',
    decimals: 8,
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    isSwappable: true,
  },
  LRB: {
    symbol: 'LRB',
    walletSymbol: 'LRB',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'LRB',
    decimals: 8,
    hash: '0x8c07b4c9f5bc170a3922eac4f5bb7ef17b0acc8b',
    isSwappable: true,
  },
  CANDY: {
    symbol: 'CANDY',
    walletSymbol: 'CANDY',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'CANDY',
    decimals: 9,
    hash: '0x88da18a5bca86ec8206d9b4960a7d0c4355a432f',
    isSwappable: true,
    volatile: true,
  },
  DOGER: {
    symbol: 'DOGER',
    walletSymbol: 'DOGER',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'DOGER',
    decimals: 8,
    hash: '0x322b5a366ca724801a1aa01e669b5f3d7f8c7f6f',
    isSwappable: true,
    volatile: true,
  },
  DOGEF: {
    symbol: 'DOGEF',
    walletSymbol: 'DOGEF',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'DOGEF',
    decimals: 8,
    hash: '0xa3291b66f70d4687fc0e41977d8acb0699f235ae',
    isSwappable: true,
    volatile: true,
  },
  fUSDT: {
    symbol: 'fUSDT',
    walletSymbol: 'fUSDT',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'USDT',
    decimals: 6,
    canBeUnwrappedTo: 'USDT',
    hash: '0xcd48b160c1bbc9d74997b803b9a7ad50a4bef020',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'USDT',
  },
  fWETH: {
    symbol: 'fWETH',
    walletSymbol: 'fWETH',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'ETH',
    decimals: 18,
    canBeUnwrappedTo: 'WETH',
    hash: '0xc14b601252aa5dfa6166cf35fe5ccd2e35f3fdf5',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'WETH',
  },
  fWBTC: {
    symbol: 'fWBTC',
    walletSymbol: 'fWBTC',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'BTC',
    canBeUnwrappedTo: 'WBTC',
    decimals: 8,
    hash: '0xd6abe115ecb75e1fa0b42f5e85934ce8c1ae2893',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'WBTC',
  },
  pONT: {
    symbol: 'pONT',
    walletSymbol: 'pONT',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'ONT',
    canBeUnwrappedTo: 'ONTd',
    decimals: 9,
    hash: '8122bc2212ec971690a044b37a6f52a9349b702b',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'ONTd',
  },
  pWING: {
    symbol: 'pWING',
    walletSymbol: 'WING',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'WING',
    canBeUnwrappedTo: 'WING',
    decimals: 9,
    hash: '0xeeccd60ed722111f8400434dac3ba42c14d8beb1',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'WING',
  },
  GM: {
    symbol: 'GM',
    walletSymbol: 'GM',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'GM',
    decimals: 8,
    hash: '0x9b049f1283515eef1d3f6ac610e1595ed25ca3e9',
    isSwappable: true,
  },
  fCAKE: {
    symbol: 'fCAKE',
    walletSymbol: 'fCAKE',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'CAKE',
    canBeUnwrappedTo: 'CAKE',
    decimals: 18,
    hash: '0xe65b462b90516012826f8a9c4c285d8c750e3a77',
    isSwappable: true,
    wrapperMethods: ['lock', 'unlockAndIncomeWithdraw'],
    wrapperTarget: 'CAKE',
  },
  SWTH: {
    symbol: 'SWTH',
    walletSymbol: 'SWTH',
    chainId: CHAIN_ID_NEO,
    unwrappedSymbol: 'SWTH',
    decimals: 8,
    hash: '0x78e1330db47634afdb5ea455302ba2d12b8d549f',
    isSwappable: true,
  },
  // ============ Ethereum Assets ============ //
  WETH: {
    symbol: 'WETH',
    walletSymbol: 'WETH',
    unwrappedSymbol: 'ETH',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 18,
    hash: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    canBeWrappedTo: 'fWETH',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'FWETH',
    wrapperPolyAsset: 'xWETH',
    incomeBridgeHash: '0xa7cd136ACEC4347410B373D99f8a8EE1c60372Cf',
  },
  WBTC: {
    symbol: 'WBTC',
    walletSymbol: 'WBTC',
    unwrappedSymbol: 'BTC',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 8,
    hash: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    canBeWrappedTo: 'fWBTC',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'fWBTC',
    wrapperPolyAsset: 'xWBTC',
    incomeBridgeHash: '0xCB9d4f7DD3E3D1D2E580Dd7e869012BC00829933',
  },
  USDT: {
    symbol: 'USDT',
    walletSymbol: 'USDT',
    unwrappedSymbol: 'USDT',
    chainId: CHAIN_ID_ETHEREUM,
    decimals: 6,
    hash: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    canBeWrappedTo: 'fUSDT',
    wrapperMethods: ['incomeDepositAndLock', 'unlock'],
    wrapperTarget: 'fUSDT',
    wrapperPolyAsset: 'xUSDT',
    incomeBridgeHash: '0xE1b7b517a32223D9959118B01878729b2af39ea3',
  },
  xWETH: {
    symbol: 'xWETH',
    walletSymbol: 'xWETH',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0x46b2E0224efb03D43eba88AB5A1995480e6A76b6',
    decimals: 18,
    incomeV2: true,
    incomeType: 'xToken',
  },
  xWBTC: {
    symbol: 'xWBTC',
    walletSymbol: 'xWBTC',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0xebd0e8988ac25a793dc27368f96a5a72e34efcd7',
    decimals: 8,
    incomeV2: true,
    incomeType: 'xToken',
  },
  xUSDT: {
    symbol: 'xUSDT',
    walletSymbol: 'xUSDT',
    chainId: CHAIN_ID_ETHEREUM,
    hash: '0xa93727e8661d4f82cfd50f7d8fd3f38ec8493b84',
    decimals: 6,
    incomeV2: true,
    incomeType: 'xToken',
  },
  // ============ Ontology Assets ============ //
  ONTd: {
    symbol: 'ONTd',
    walletSymbol: 'ONTd',
    unwrappedSymbol: 'ONT',
    chainId: CHAIN_ID_ONTOLOGY,
    hash: '33ae7eae016193ba0fe238b223623bc78faac158',
    canBeWrappedTo: 'pONT',
    decimals: 9,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'pONT',
  },
  WING: {
    symbol: 'WING',
    walletSymbol: 'WING',
    unwrappedSymbol: 'WING',
    chainId: CHAIN_ID_ONTOLOGY,
    hash: '00c59fcd27a562d6397883eab1f2fff56e58ef80',
    canBeWrappedTo: 'pWING',
    decimals: 9,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'pWING',
  },
  // ============ BSC Assets ============ //
  CAKE: {
    symbol: 'CAKE',
    walletSymbol: 'CAKE',
    unwrappedSymbol: 'CAKE',
    chainId: CHAIN_ID_BSC,
    hash: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    canBeWrappedTo: 'fCAKE',
    decimals: 18,
    wrapperMethods: ['deposit', 'unlock'],
    wrapperTarget: 'fCAKE',
    wrapperPolyAsset: 'xfCAKE',
    incomeBridgeHash: '0x1176b1e362a23ab750a6d2208dfba4a716c7d102',
  },
  xfCAKE: {
    symbol: 'xfCAKE',
    walletSymbol: 'xfCAKE',
    chainId: CHAIN_ID_BSC,
    hash: '0x1176b1e362a23ab750a6d2208dfba4a716c7d102',
    decimals: 18,
  },
  bNUDES: {
    symbol: 'bNUDES',
    walletSymbol: 'NUDES',
    unwrappedSymbol: 'NUDES',
    chainId: CHAIN_ID_BSC,
    hash: '0x301ff7c013ec7043ffb9453cd3fb32754ccaa1a5',
    canBeUnwrappedTo: 'NUDES',
    decimals: 8,
    wrapperMethods: ['lock', 'unlock'],
    wrapperTarget: 'NUDES',
  },
};

const tokens = TARGET_MAINNET ? mainnetTokens : testnetTokens;

export default {
  ...tokens,
};
