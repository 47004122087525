import { computed } from 'vue';
import store from '@/store';
import o3 from '@/os/blockchain/wallet/o3';
import neoline from '@/os/blockchain/wallet/neoline';
import rpcAPI from '@/os/APIs/rpcAPI';
import contractAPI from '@/os/APIs/contractAPI';
import walletConnect from '@/os/blockchain/wallet/walletconnect';
import oneGate from '@/os/blockchain/wallet/onegate';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

const state = {
  walletConnected: computed(() => store.state.blockchain.conditions.walletConnected),
  conditions: computed(() => store.state.blockchain.conditions),
  walletData: computed(() => store.state.blockchain.wallet),
  ethWalletData: computed(() => store.state.blockchain.ethWallet),
  binanceWalletData: computed(() => store.state.blockchain.binanceWallet),
  ontWalletData: computed(() => store.state.blockchain.ontWallet),
  userUnclaimedFLM: computed(() => store.state.blockchain.userPoolData.totalUnclaimedFLM),
  poolData: computed(() => store.state.blockchain.poolData),
  userPoolData: computed(() => store.state.blockchain.userPoolData),
  counter: computed(() => store.state.blockchain.counter),
};

function checkNetwork() {
  const walletMainNet = state.walletData.value.mainNet;

  if (TARGET_MAINNET && !walletMainNet) {
    const errorMessage = 'Wallet is connected to TestNet, Please switch to MainNet walletAPI';
    window.toast.error(errorMessage);
    return false;
  }

  if (!TARGET_MAINNET && walletMainNet) {
    const errorMessage = 'Wallet is connected to MainNet, Please switch to TestNet walletAPI';
    window.toast.error(errorMessage);
    return false;
  }

  return true;
}

function walletIsSupported(walletName) {
  const supportedWallets = ['O3', 'NEOLINE', 'WalletConnect', 'OneGate'];
  return supportedWallets.includes(walletName);
}

async function initWallets() {
  let result = false;
  const savedWallet = JSON.parse(localStorage.getItem('lastWallet'));

  if (savedWallet !== null) {
    if (savedWallet.type === 'O3') await o3.init();
    if (savedWallet.type === 'NEOLINE') await neoline.init();
    if (savedWallet.type === 'WalletConnect') await walletConnect.init();
    if (savedWallet.type === 'OneGate') await oneGate.init();

    result = true;
  }

  return result;
}

async function getWalletBalance() {
  if (state.walletConnected.value) {
    const walletName = state.walletData.value.type;

    if (walletIsSupported(walletName) === false) {
      throw new Error(`Wallet "${walletName}" is not supported.`);
    }

    let balances;

    if (walletName === 'O3') {
      balances = await o3.getBalance();
    }

    if (walletName === 'NEOLINE') {
      if (!checkNetwork()) return;
      balances = await neoline.getBalance();
    }

    if (walletName === 'WalletConnect') {
      balances = await walletConnect.getBalance();
    }

    if (walletName === 'OneGate') {
      balances = await oneGate.getBalance();
    }

    store.commit('blockchain/setWalletBalance', balances);
  }
}

async function disconnectWallet() {
  const walletName = state.walletData.value.type;

  if (walletName === 'O3') {
    await o3.disconnectO3();
  }

  if (walletName === 'NEOLINE') {
    await neoline.disconnect();
  }

  if (walletName === 'WalletConnect') {
    await walletConnect.disconnect();
  }

  if (walletName === 'OneGate') {
    await oneGate.disconnect();
  }

  store.commit('blockchain/clearWallet');
}

async function connectWallet(walletName) {
  let data;

  if (walletIsSupported(walletName) === false) {
    throw new Error(`Wallet ${walletName} is not supported.`);
  }

  if (walletName === 'O3') {
    data = await o3.connect();
    data.type = 'O3';
    data.balances = [];
  }

  if (walletName === 'NEOLINE') {
    data = await neoline.connect();
    data.type = 'NEOLINE';
    data.balances = [];
    await neoline.queryState();
  }

  if (walletName === 'WalletConnect') {
    await walletConnect.connect();
  }

  if (walletName === 'OneGate') {
    await oneGate.init();
  }

  store.commit('blockchain/setWalletData', data);
  await getWalletBalance();
}

async function invokeWallet(invocations, allowedContracts) {
  const walletName = state.walletData.value.type;

  if (walletIsSupported(walletName) === false) {
    throw new Error(`InvokeMulti not supported for wallet ${walletName}.`);
  }

  if (walletName === 'O3') {
    return o3.invokeMulti(invocations, allowedContracts);
  }

  if (walletName === 'NEOLINE') {
    if (!checkNetwork()) return false;
    return neoline.invokeMulti(invocations, allowedContracts);
  }

  if (walletName === 'WalletConnect') {
    return walletConnect.invokeMulti(invocations, allowedContracts);
  }

  if (walletName === 'OneGate') {
    return oneGate.invokeMulti(invocations, allowedContracts);
  }

  return false;
}

async function getGasReward() {
  const curBlockIndex = await rpcAPI.getCurrentBlock();
  const curBlockTime = await rpcAPI.getBlock(curBlockIndex);
  const prevBlocktime = await rpcAPI.getBlock(curBlockIndex - 1000);
  const generationRate = (curBlockTime.time - prevBlocktime.time) / 1000;

  const validators = await rpcAPI.getBlockValidators();

  const userVote = await rpcAPI.getUserVote(contractAPI.getContractByName('NEO').hash, `0x${store.state.blockchain.wallet.hash}`);
  validators.sort((a, b) => parseInt(b.votes, 10) - parseInt(a.votes, 10));

  let gasPrSec = 0;
  if (userVote.stack[0].type === 'Struct') {
    const candidateKey = userVote.stack[0].value[2].type === 'Any' ? '' : Buffer.from(userVote.stack[0].value[2].value, 'base64').toString('hex');
    const voteNum = validators.findIndex((item) => item.publickey === candidateKey);

    const neoHolding = parseInt(userVote.stack[0].value[0].value, 10);
    if (voteNum >= 21 || voteNum === -1) {
      gasPrSec = ((5 * (0.1 / 1000000)) / generationRate) * neoHolding;
    } else if (voteNum >= 7) {
      gasPrSec = ((5 * ((0.1 / 1000000) + (0.4 / 14 / parseInt(validators[voteNum].votes, 10)))) / generationRate) * neoHolding;
    } else {
      gasPrSec = ((5 * ((0.1 / 1000000) + (0.4 / 7 / parseInt(validators[voteNum].votes, 10)))) / generationRate) * neoHolding;
    }
  }

  const neoClaimableGas = await rpcAPI.getUnclaimedGas(store.state.blockchain.wallet.address);
  const bneoClaimableGas = await rpcAPI.invokeFunctionRpc(
    contractAPI.getContractByName('burger').hash,
    'reward',
    {
      type: 'Hash160',
      value: `0x${store.state.blockchain.wallet.hash}`,
    },
  );
  const claimableGas = (parseFloat(neoClaimableGas.unclaimed) + parseFloat(bneoClaimableGas.value)) / (10 ** contractAPI.getContractByName('GAS').decimals);

  const gasData = {
    unclaimedGas: claimableGas,
    rewardPrSec: gasPrSec,
  };
  store.commit('blockchain/setGasData', gasData);
  setInterval(() => {
    store.commit('blockchain/addUnclaimedGas');
  }, 1000);
  return 0;
}

async function claimGas() {
  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('bNEO').hash,
      operation: 'transfer',
      args: [
        {
          type: 'Hash160',
          value: `0x${store.state.blockchain.wallet.hash}`,
        },
        {
          type: 'Hash160',
          value: contractAPI.getContractByName('bNEO').hash,
        },
        {
          type: 'Integer',
          value: 0,
        },
        {
          type: 'Any',
          value: '',
        },
      ],
    },
    {
      scriptHash: contractAPI.getContractByName('NEO').hash,
      operation: 'transfer',
      args: [
        {
          type: 'Hash160',
          value: `0x${store.state.blockchain.wallet.hash}`,
        },
        {
          type: 'Hash160',
          value: `0x${store.state.blockchain.wallet.hash}`,
        },
        {
          type: 'Integer',
          value: 0,
        },
        {
          type: 'Any',
          value: '',
        },
      ],
    },
  ];
  const allowedContracts = [
    contractAPI.getContractByName('bNEO').hash,
    contractAPI.getContractByName('NEO').hash,
  ];
  const tx = await invokeWallet(invocations, allowedContracts);
  return tx;
}

export default {
  state,
  initWallets,
  getWalletBalance,
  connectWallet,
  disconnectWallet,
  invokeWallet,
  getGasReward,
  claimGas,
};
