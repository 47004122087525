/* eslint-disable */
import contractAPI from '@/os/APIs/contractAPI';
import walletAPI from '@/os/APIs/walletAPI';
import { sc } from '@cityofzion/neon-js';
import rpcAPI from '@/os/APIs/rpcAPI';

async function mint(mintAmount, price) {
  const allowedContracts = [
    contractAPI.getContractByName('DOGER').hash,
    contractAPI.getContractByName('dogeRift').hash,
  ];
  const mintScript = {
    scriptHash: contractAPI.getContractByName('DOGER').hash,
    operation: 'transfer',
    args: [
      {
        type: 'Address',
        value: walletAPI.state.walletData.value.address,
      },
      {
        type: 'Hash160',
        value: contractAPI.getContractByName('dogeRift').hash,
      },
      {
        type: 'Integer',
        value: mintAmount * price * (10 ** contractAPI.getContractByName('DOGER').decimals),
      },
      {
        type: 'Array',
        value: [
          {
            type: 'String',
            value: 'mint',
          },
          {
            type: 'Integer',
            value: mintAmount,
          },
        ],
      },
    ],
  };
  return await walletAPI.invokeWallet([mintScript], allowedContracts, mintAmount);
}

async function getElements() {
  const dogeRiftHash = contractAPI.getContractByName('dogeRift').hash;
  let rawScript = sc.createScript({
    scriptHash: dogeRiftHash,
    operation: 'getElements',
    args: [],
  });

  let base = Buffer.from(rawScript, 'hex').toString('base64');

  let response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: '0x96e68132d9c7a3e7ff0307c8d1af63a597423248',
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });
  if (response?.result?.state === 'FAULT') throw new Error('Getting Doge NFT failed.');
  rawScript = [];
  response?.result?.stack[0]?.iterator?.map((item) => {
    rawScript.push(sc.createScript({
      scriptHash: dogeRiftHash,
      operation: 'getElement',
      args: [{
        type: 'ByteArray',
        value: item.value,
      }],
    }));
    rawScript.push(sc.createScript({
      scriptHash: dogeRiftHash,
      operation: 'getElementSupply',
      args: [{
        type: 'ByteArray',
        value: item.value,
      }],
    }));
    rawScript.push(sc.createScript({
      scriptHash: dogeRiftHash,
      operation: 'getElementCount',
      args: [{
        type: 'ByteArray',
        value: item.value,
      }],
    }));
  });
  base = Buffer.from(rawScript.join(''), 'hex').toString('base64');

  response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: '0x96e68132d9c7a3e7ff0307c8d1af63a597423248',
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });
  const nftList = [];
  if (response?.result?.state === 'FAULT') throw new Error('Getting Doge NFT failed.');
  const rpcRes = response?.result?.stack;
  for (let idx = 0; idx < rpcRes?.length; idx += 3) {

    const tmp = JSON.parse(Buffer.from(rpcRes[idx].value, 'base64').toString());
    tmp.totalAmount = parseInt(rpcRes[idx + 1].value, 10);
    tmp.mintAmount = parseInt(rpcRes[idx + 2].value, 10);
    nftList.push(tmp);
  }
  return nftList;
}

async function getPrice() {
  const rawScript = sc.createScript({
    scriptHash: contractAPI.getContractByName('dogeRift').hash,
    operation: 'getPrice',
    args: [],
  });

  const base = Buffer.from(rawScript, 'hex').toString('base64');

  const response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: contractAPI.getContractByName('dogeRift').hash,
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });

  if (response?.result?.state === 'FAULT') throw new Error('Getting Nft price failed.');
  const doger = contractAPI.getContractByName('DOGER');

  return parseInt(response.result.stack[0].value, 10) / (10 ** doger.decimals);
}

async function getTotalNfts() {
  const rawScript = sc.createScript({
    scriptHash: contractAPI.getContractByName('dogeRift').hash,
    operation: 'getTotalNfts',
    args: [],
  });

  const base = Buffer.from(rawScript, 'hex').toString('base64');

  const response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: contractAPI.getContractByName('dogeRift').hash,
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });

  if (response?.result?.state === 'FAULT') throw new Error('Getting Nft Total Count failed.');
  return parseInt(response.result.stack[0].value, 10);
}

async function getNftsCount() {
  const rawScript = sc.createScript({
    scriptHash: contractAPI.getContractByName('dogeRift').hash,
    operation: 'getNftsCount',
    args: [],
  });

  const base = Buffer.from(rawScript, 'hex').toString('base64');

  const response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: contractAPI.getContractByName('dogeRift').hash,
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });
  if (response?.result?.state === 'FAULT') throw new Error('Getting Nft Count failed.');
  return parseInt(response.result.stack[0].value, 10);
}

async function getTokensOf() {
  const rawScript = sc.createScript({
    scriptHash: contractAPI.getContractByName('dogeRift').hash,
    operation: 'tokensOf',
    args: [
      {
        type: 'Hash160',
        value: walletAPI.state.walletData.value.address,
      }
    ],
  });

  const base = Buffer.from(rawScript, 'hex').toString('base64');

  const response = await rpcAPI.rawRpc({
    jsonrpc: '2.0',
    id: 1,
    method: 'invokescript',
    params: [base,
      [
        {
          account: walletAPI.state.walletData.value.hash,
          scopes: 'Global',
          allowedcontracts: [],
          allowedgroups: [],
        },
      ],
    ],
  });

  if (response?.result?.state === 'FAULT') throw new Error('Getting My Collection failed.');

  const collectionList = [];
  response.result.stack[0].iterator.map((item) => {
    const tmp = Buffer.from(item.value, 'base64')?.toString()?.split(' #')[0];
    collectionList.push(tmp);
  });
  return collectionList;
}

export default {
  getElements,
  getPrice,
  getTotalNfts,
  getTokensOf,
  mint,
  getNftsCount,
};
