<template>
  <div class="h-full flex">
  <connect-wallet></connect-wallet>
  <div class="w-full min-h-full text-white bg-black flex flex-col">
    <router-view/>
  </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, onBeforeMount } from 'vue';
import ConnectWallet from '@/os/components/modals/ConnectWallet.vue';
import walletAPI from '@/os/APIs/walletAPI';

export default defineComponent({
  name: 'Home',
  components: {
    ConnectWallet,
  },
  setup() {
    const store = useStore();
    store.dispatch('prices/init');

    onBeforeMount(async () => {
      await walletAPI.initWallets();
    });
    return {};
  },
});
</script>

<style>
body {
  background-color: #000;
  overflow-y: scroll;
}
</style>
