import BigNumber from 'bignumber.js';
import walletAPI from '@/os/APIs/walletAPI';
import contractAPI from '@/os/APIs/contractAPI';
import chains, { CHAIN_ID_NEO } from '@/os/values/chains';
import { getChainApi } from '@/os/utils/chainApi';
import InvokeConfig from '@/os/utils/invoke';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

function toBurgerToken(amount) {
  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('NEO').hash,
      operation: 'transfer',
      args: [
        {
          type: 'Address',
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Address',
          value: contractAPI.getContractByName('burger').address,
        },
        {
          type: 'Integer',
          value: amount,
        },
        {
          type: 'Any',
          value: null,
        },
      ],
    },
  ];

  const allowedContracts = [
    contractAPI.getContractByName('NEO').hash,
    contractAPI.getContractByName('GAS').hash,
    contractAPI.getContractByName('burger').hash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

function fromBurgerToken(amount) {
  const gasAmount = new BigNumber(amount).multipliedBy('0.001');

  const invocations = [
    {
      scriptHash: contractAPI.getContractByName('GAS').hash,
      operation: 'transfer',
      args: [
        {
          type: 'Address',
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Address',
          value: contractAPI.getContractByName('burger').address,
        },
        {
          type: 'Integer',
          value: gasAmount.toFixed(),
        },
        {
          type: 'Any',
          value: null,
        },
      ],
    },
  ];

  const allowedContracts = [
    contractAPI.getContractByName('NEO').hash,
    contractAPI.getContractByName('GAS').hash,
    contractAPI.getContractByName('burger').hash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

async function lock(assetHash, toChainId, toAddress, amount) {
  const chain = chains.find((c) => c.id === CHAIN_ID_NEO);

  const toChainApi = await getChainApi(toChainId);
  const toAddressHex = toChainApi.addressToHex(toAddress);
  const toAddressB64 = Buffer.from(toAddressHex, 'hex').toString('base64');

  const invocations = [
    {
      scriptHash: chain.lockContractHash,
      operation: 'lock',
      args: [
        {
          type: 'Hash160',
          value: assetHash,
        },
        {
          type: 'Address',
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Integer',
          value: toChainId,
        },
        {
          type: 'ByteArray',
          value: toAddressB64,
        },
        {
          type: 'Integer',
          value: amount,
        },
        {
          type: 'Integer',
          value: TARGET_MAINNET ? 1 : 10086,
        },
      ],
    },
  ];

  const allowedContracts = [
    chain.lockContractHash,
    assetHash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

async function unlock({ args }) {
  const chain = chains.find((c) => c.id === CHAIN_ID_NEO);

  function hexToBase64(hex) {
    return Buffer.from(hex, 'hex').toString('base64');
  }

  const invocations = [
    {
      scriptHash: chain.unlockContractHash,
      operation: 'verifyAndExecuteTx',
      args: args.map((arg) => ({ type: 'ByteArray', value: hexToBase64(arg) })),
    },
  ];

  const allowedContracts = [
    chain.unlockContractHash,
  ];

  return new InvokeConfig(invocations, allowedContracts);
}

export default {
  toBurgerToken,
  fromBurgerToken,
  lock,
  unlock,
};
