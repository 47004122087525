const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

const testnetContracts = {
  swap: {
    hash: '0x13a83e059c2eedd5157b766d3357bc826810905e',
  },
  staking: {
    hash: '0x999d9d6b1b0aad6efcc0b49b1d484fedf803a4a0',
  },
  swapWhitelist: {
    hash: '0x06f12a6aa2b5689ce97f16979b179fb3e31d63d7',
  },
  swapFactory: {
    hash: '0x701f7fe4c8d325487b64d718419a2a5a4a5e38eb',
  },
  burger: {
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    address: 'NPmdLGJN47EddqYcxixdGMhtkr7Z5w4Aos',
  },
  dogeRift: {
    hash: '0x46044ead0e568f3700a26398b578e2cf624c04c1',
  },
};

const mainnetContracts = {
  swap: {
    hash: '0xf970f4ccecd765b63732b821775dc38c25d74f23',
  },
  staking: {
    hash: '0xd1a9f78e1940f6322fef4df2340a963a9ec46f63',
  },
  swapWhitelist: {
    hash: '0xfb75a5314069b56e136713d38477f647a13991b4',
  },
  swapFactory: {
    hash: '0xca2d20610d7982ebe0bed124ee7e9b2d580a6efc',
  },
  burger: {
    hash: '0x48c40d4666f93408be1bef038b6722404d9a4c2a',
    address: 'NPmdLGJN47EddqYcxixdGMhtkr7Z5w4Aos',
  },
  dogeRift: {
    hash: '0x7bd7b0af7f9085bb4f6f2e3f956ec9ff5a494645',
  },
};

const contracts = TARGET_MAINNET ? mainnetContracts : testnetContracts;

export default {
  ...contracts,
};
