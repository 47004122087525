export default ({
  namespaced: true,
  state: () => ({
    wallet: {
      address: '',
      hash: '',
      type: '',
      label: '',
      balances: [],
    },
    conditions: {
      walletConnected: false,
      balances: false,
      connecting: false,
    },
  }),
  mutations: {
    setWalletData(state, data) {
      state.wallet = { ...state.wallet, ...data };
      state.conditions.walletConnected = true;
      state.conditions.connecting = false;
    },
    initializeConnection(state) {
      state.conditions.connecting = true;
    },
    clearWallet(state) {
      state.wallet = {
        address: '',
        hash: '',
        type: '',
        label: '',
        balances: [],
      };
      state.userPoolData = [];
      state.conditions.connecting = false;
      state.conditions.walletConnected = false;
      state.conditions.balances = false;
      state.conditions.userPoolData = false;
      sessionStorage.removeItem('connect');
      localStorage.removeItem('lastWallet');
    },
    setWalletBalance(state, data) {
      state.wallet.balances = data;
      state.conditions.balances = true;
    },
    setPoolData(state, data) {
      state.poolData = data;
      state.conditions.poolData = true;
    },
    setUserPoolData(state, data) {
      state.userPoolData = data;
      state.conditions.userPoolData = true;
    },
  },
  actions: {},
  getters: {},
  modules: {},
});
