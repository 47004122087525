export default ({
  namespaced: true,
  state: () => ({
    activeModal: false,
  }),
  mutations: {
    setActiveModal(state, modal) {
      state.activeModal = modal === '' ? false : modal;
    },
  },
});
