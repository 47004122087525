/* eslint-disable */
import axios from 'axios';
import utilsAPI from '@/os/APIs/utilsAPI';

export class RpcResponseError extends Error {
  constructor(message, data, code) {
    super(message);
    this.message = message;
    this.data = data;
    this.code = code;
  }
}

export class RpcError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

const api = axios.create({
  baseURL: process.env.VUE_APP_RPC_URL,
});

async function rawRpc(args) {
  const response = await api.post('/', args);

  if (response.data.error !== undefined) {
    const error = response.data.error;
    throw new RpcResponseError(error.message, error.data, error.code);
  }

  return response.data;
}

async function getApplicationLog(txId) {
  const tx = txId.startsWith('0x') ? txId : `0x${txId}`;

  const response = await rawRpc({
    id: 1,
    rpc: '2.0',
    method: 'getapplicationlog',
    params: [tx],
  });

  return response.result.executions[0];
}

async function checkTxStatus(txId) {
  try {
    let tx = await getApplicationLog(txId);

    if (tx.vmstate === 'FAULT') {
      throw new Error(`${txId} is fault`);
    }

    return tx;
  } catch (error) {
    if (error instanceof RpcResponseError) {
      if (error.code === -100) {
        await utilsAPI.sleep(2000);
        return checkTxStatus(txId);
      }
    }

    throw error;
  }
}

async function invokeFunctionRpc(contractHash, method, ...args) {
  const response = await rawRpc({
    id: 1,
    rpc: '2.0',
    method: 'invokefunction',
    params: [contractHash, method, args],
  });

  if (response.result.state !== 'HALT') {
    throw new RpcError('RPC failed!', response);
  }

  return response.result.stack[0];
}

export default {
  rawRpc,
  invokeFunctionRpc,
  getApplicationLog,
  checkTxStatus,
};
