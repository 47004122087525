/* eslint-disable no-undef */
import neo3Dapi from 'neo3-dapi';
import store from '@/store';
import walletUtilities from './walletUtilities';

let wallet;
const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

async function disconnectO3() {
  await neo3Dapi.disconnect();
}

async function invokeMulti(invocations, allowedContracts) {
  const invokeScript = {
    invokeArgs: invocations,
    signers: [
      {
        account: `0x${wallet.hash}`,
        scopes: 16,
        allowedContracts,
      },
    ],
    network: TARGET_MAINNET ? 'N3MainNet' : 'N3TestNet',
  };

  const result = await neo3Dapi.invokeMulti(invokeScript)
    .catch((error) => {
      walletUtilities.errorHandler(error.type, data.txType);
    });

  if (typeof data === 'undefined') return false;

  return result.txid;
}

async function getBalance() {
  const data = await neo3Dapi.getBalance({
    params: { address: wallet.address },
    network: TARGET_MAINNET ? 'N3MainNet' : 'N3TestNet',
  })
    .catch((error) => {
      walletUtilities.errorHandler(error.type, 'WALLET');
    });

  if (typeof data === 'undefined') return [];

  return data[wallet.address];
}

async function queryState() {
  const account = await neo3Dapi.getAccount();
  const hash = walletUtilities.addressToHash(account.address);
  const networks = await neo3Dapi.getNetworks();

  wallet = {
    type: 'O3',
    address: account.address,
    balances: [],
    hash,
    label: account.label,
    connected: true,
    mainNet: Number(networks.chainId) === 3,
  };
  const balances = await getBalance();
  localStorage.setItem('lastWallet', JSON.stringify(wallet));
  store.commit('blockchain/setWalletData', wallet);
  store.commit('blockchain/setWalletBalance', balances);
  return wallet;
}

async function init() {
  const data = await queryState();
  neo3Dapi.addEventListener(neo3Dapi.Constants.EventName.ACCOUNT_CHANGED, () => {
    queryState();
  });
  neo3Dapi.addEventListener(neo3Dapi.Constants.EventName.NETWORK_CHANGED, () => {
    // TODO: Implement network change detection.
  });
  return data;
}

async function connect() {
  return init();
}

export default {
  connect,
  disconnectO3,
  invokeMulti,
  getBalance,
  init,
};
