import axios from 'axios';

export default ({
  namespaced: true,
  state: () => ({
    tokens: {
      USDTUSDT: 1.00,
      BTCUSDT: 0.00,
      USDTBTC: 0.00,
      NEOBTC: 0.00,
      NEOUSDT: 0.00,
      GASBTC: 0.00,
      GASUSDT: 0.00,
      FLMBTC: 0.00,
      FLMUSDT: 0.00,
      ONTBTC: 0.00,
      ONTUSDT: 0.00,
      ETHBTC: 0.00,
      ETHUSDT: 0.00,
    },
    conditions: {
      prices: false,
    },
  }),
  actions: {
    async init({ state }) {
      const initPriceRequest = await axios.get('https://api.binance.com/api/v3/ticker/price');

      Object.keys(state.tokens).forEach((token) => {
        const currentToken = initPriceRequest.data.filter((t) => t.symbol === token);
        if (currentToken.length > 0) {
          state.tokens[token] = parseFloat(currentToken[0].price);
          if (token === 'GASBTC') {
            state.tokens.GASUSDT = parseFloat(currentToken[0].price) * state.tokens.BTCUSDT;
          }
        }
      });

      const socket = new WebSocket(
        'wss://stream.binance.com:9443/ws/'
        + 'neobtc@aggTrade/'
        + 'neousdt@aggTrade/'
        + 'gasbtc@aggTrade/'
        + 'flmbtc@aggTrade/'
        + 'flmusdt@aggTrade/'
        + 'btcusdt@aggTrade/'
        + 'ontbtc@aggTrade/'
        + 'ontusdt@aggTrade/'
        + 'ethbtc@aggTrade/'
        + 'ethusdt@aggTrade',
      );

      const timeouts = {};
      const maxUpdateInterval = 2000;

      socket.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);

        // Prevent prices from updating to fast.
        if (timeouts[data.s] === undefined) timeouts[data.s] = Date.now();
        if (Date.now() < timeouts[data.s] + maxUpdateInterval) return;
        timeouts[data.s] = Date.now();

        if (data.s === 'BTCUSDT') {
          state.tokens.USDTBTC = 1 / parseFloat(data.p);
        }

        if (data.s === 'GASBTC') {
          state.tokens.GASUSDT = parseFloat(data.p) * state.tokens.BTCUSDT;
        }

        state.tokens[data.s] = parseFloat(data.p);
      });

      state.conditions.prices = true;
    },
  },
  getters: {},
  modules: {},
});
