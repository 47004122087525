import { NeoDapi } from '@neongd/neo-dapi';
import store from '@/store';
import contractAPI from '@/os/APIs/contractAPI';
import walletUtilities from './walletUtilities';

let walletInfo = {};
let walletAddress;
let dapi = null;

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

async function init() {
  if (!isMobile()) {
    window.toast.error('OneGate is only supported on mobile');
    return;
  }
  dapi = new NeoDapi(window.OneGate);
  const account = await dapi.getAccount();
  const networks = await dapi.getNetworks();
  walletAddress = account.address;
  walletInfo = {
    type: 'OneGate',
    address: walletAddress,
    balances: [],
    hash: walletUtilities.addressToHash(walletAddress),
    label: account.label, // Wallet name eg. Neon Wallet
    connected: true,
    mainNet: networks.defaultNetwork === 'MainNet',
  };
  localStorage.setItem('lastWallet', JSON.stringify(walletInfo));
  store.commit('blockchain/setWalletData', walletInfo);
}

async function getBalance() {
  if (!dapi) await init();
  const balances = await dapi.getNep17Balances({
    address: walletAddress,
    // assetHashes: [
    //   '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
    //   '0xd2a4cff31913016155e38e474a2c06d08be276cf',
    // ],
  }).catch((error) => {
    walletUtilities.errorHandler(error.type, 'WALLET');
  });
  /* eslint-disable no-param-reassign */
  balances.forEach((item) => {
    const tokenInfo = contractAPI.getContractByHash(item.assetHash);
    item.symbol = tokenInfo ? tokenInfo.symbol : '';
    item.amount = item.amount > 0 ? item.amount / (10 ** (tokenInfo ? tokenInfo.decimals : 8)) : item.amount;
    item.contract = item.assetHash;
  });
  return balances;
}

async function disconnect() {
  dapi = null;
  localStorage.removeItem('lastWallet');
  await store.commit('blockchain/clearWallet');
}

async function invokeMulti(invocations, allowedContracts) {
  if (!dapi) return;

  const invokeScript = {
    invocations,
    signers: [
      {
        account: walletInfo.hash,
        scopes: 'CustomContracts',
        allowedContracts,
      },
    ],
  };
  const resp = await dapi.invokeMulti(invokeScript);
  // eslint-disable-next-line consistent-return
  return resp.txid;
}
export default {
  init,
  getBalance,
  disconnect,
  invokeMulti,
};
