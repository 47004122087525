/* eslint-disable no-return-assign,no-loop-func,consistent-return */
import nativeContracts from '@/os/values/contracts';
import tokens from '@/os/values/tokens';
import liquidityPools from '@/os/values/pools';
import { CHAIN_ID_NEO } from '@/os/values/chains';

function getContractByName(name) {
  if (Object.keys(tokens).includes(name)) {
    return tokens[name];
  }
  if (Object.keys(liquidityPools).includes(name)) {
    return liquidityPools[name];
  }
  if (Object.keys(nativeContracts).includes(name)) {
    return nativeContracts[name];
  }
  return false;
}

function getContractByHash(hash) {
  if (hash.startsWith('0x')) {
    let obj = Object.values(tokens).find((item) => item.hash === hash);
    if (typeof obj !== 'undefined') return obj;
    obj = Object.values(liquidityPools).find((item) => item.hash === hash);
    if (typeof obj !== 'undefined') return obj;
    obj = Object.values(nativeContracts).find((item) => item.hash === hash);
    if (typeof obj !== 'undefined') return obj;
  }
  return false;
}

function getPoolByTokenHashes(tokenA, tokenB) {
  return Object.values(liquidityPools).find((p) => {
    const matchesAB = p.tokens[0].hash === tokenA && p.tokens[1].hash === tokenB;
    const matchesBA = p.tokens[0].hash === tokenB && p.tokens[1].hash === tokenA;
    return matchesAB || matchesBA;
  });
}

function getAllPools() {
  return liquidityPools;
}

function getAllTokens() {
  return tokens;
}

function getNeoTokens() {
  return Object.values(tokens).filter((token) => token.chainId === CHAIN_ID_NEO);
}

function createPoolGraph() {
  const poolGraph = {};

  Object.keys(tokens).forEach((tokenName) => poolGraph[tokenName] = new Set());

  Object.values(liquidityPools).forEach((pool) => {
    const tokenA = pool.tokens[0].symbol;
    const tokenB = pool.tokens[1].symbol;
    poolGraph[tokenA].add(tokenB);
    poolGraph[tokenB].add(tokenA);
  });

  Object.keys(tokens).forEach((tokenName) => poolGraph[tokenName] = [...poolGraph[tokenName]]);

  return poolGraph;
}

export function shortestRoute(startToken, targetToken) {
  const poolGraph = createPoolGraph();
  const queue = [startToken];
  const visited = { [startToken]: true };
  const predecessor = {};
  let path = null;

  while (queue.length) {
    let currentToken = queue.shift();
    const neighbours = poolGraph[currentToken];

    neighbours.forEach((neighbour) => {
      if (visited[neighbour]) return;

      visited[neighbour] = true;

      if (neighbour === targetToken) {
        path = [neighbour];

        while (currentToken !== startToken) {
          path.push(currentToken);
          currentToken = predecessor[currentToken];
        }

        path.push(currentToken);
        path.reverse();

        return false;
      }

      predecessor[neighbour] = currentToken;
      queue.push(...neighbours);
    });
  }

  return path;
}

function makeRoute(route) {
  const routes = [];
  route.forEach((hash) => {
    routes.push({
      type: 'Hash160',
      value: hash,
    });
  });
  return routes;
}

function getRoute(fromToken, toToken) {
  const route = shortestRoute(fromToken, toToken);
  return makeRoute(route.map((tokenName) => tokens[tokenName].hash));
}

function getAllHashes() {
  const allHashes = [];

  Object.values(tokens).forEach((token) => {
    if (token.chainId === CHAIN_ID_NEO) allHashes.push(token.hash);
  });

  Object.values(liquidityPools).forEach((liquidityPool) => {
    allHashes.push(liquidityPool.hash);
  });

  Object.values(nativeContracts).forEach((native) => {
    allHashes.push(native.hash);
  });

  return [...new Set(allHashes)];
}

export default {
  getRoute,
  getContractByName,
  getContractByHash,
  getPoolByTokenHashes,
  getAllPools,
  getAllTokens,
  getAllHashes,
  shortestRoute,
  getNeoTokens,
};
