/* eslint-disable no-undef */
import store from '@/store';
import walletUtilities, { WrongNetError } from './walletUtilities';

let neoline = false;
let wallet = false;
const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

async function checkNet() {
  const neolineDapi = new window.NEOLine.Init();
  const networks = await neolineDapi.getNetworks();
  const chainId = Number(networks.chainId);

  try {
    if (chainId < 3) throw new WrongNetError({ isUsingLegacy: true });
    if (TARGET_MAINNET && chainId !== 3) throw new WrongNetError({ target: 'mainnet' });
    if (!TARGET_MAINNET && chainId !== 4) throw new WrongNetError({ target: 'testnet' });
  } catch (error) {
    if (error instanceof WrongNetError) {
      window.toast.error(error.message);
      store.commit('blockchain/clearWallet');
    }
    throw error;
  }
}

async function getBalance() {
  const data = await neoline.getBalance()
    .catch((error) => {
      walletUtilities.errorHandler(error.type, 'WALLET');
    });

  if (typeof data === 'undefined') {
    return [];
  }

  const userAssets = data[wallet.address];

  return userAssets;
}

async function queryState() {
  const neolineDapi = new window.NEOLine.Init();
  const neolineDapiN3 = new window.NEOLineN3.Init();
  const account = await neolineDapi.getAccount();
  const networks = await neolineDapi.getNetworks();
  const hash = await neolineDapiN3.AddressToScriptHash({ address: account.address });

  wallet = {
    type: 'NEOLINE',
    address: account.address,
    balances: [],
    hash: hash.scriptHash,
    label: account.label,
    connected: true,
    mainNet: Number(networks.chainId) === 3,
  };
  localStorage.setItem('lastWallet', JSON.stringify(wallet));
  store.commit('blockchain/setWalletData', wallet);
  const balances = await getBalance();
  store.commit('blockchain/setWalletBalance', balances);
  return wallet;
}

function addNeoLineEventListeners() {
  let lastChange = Date.now();

  window.addEventListener('NEOLine.NEO.EVENT.NETWORK_CHANGED', () => {
    if (Date.now() < lastChange + 1000) return;
    lastChange = Date.now();
    checkNet().then(() => queryState());
  });

  window.addEventListener('NEOLine.NEO.EVENT.ACCOUNT_CHANGED', () => {
    if (Date.now() < lastChange + 1000) return;
    lastChange = Date.now();
    checkNet().then(() => queryState());
  });
}

async function connect() {
  sessionStorage.removeItem('connect');
  neoline = new NEOLineN3.Init();

  wallet = await neoline.getAccount()
    .catch((error) => {
      walletUtilities.errorHandler(error.type, 'WALLET');
    });

  if (typeof wallet === 'undefined') return false;

  addNeoLineEventListeners();

  await checkNet();

  const addressHash = await neoline.AddressToScriptHash({ address: wallet.address });
  wallet.hash = addressHash.scriptHash;

  return wallet;
}

async function disconnect() {
  sessionStorage.removeItem('connect');
  localStorage.removeItem('lastWallet');
}

async function checkConnection() {
  if (!neoline) {
    await connect();
  }
}

async function init() {
  store.commit('blockchain/initializeConnection');

  const initN3Dapi = new Promise((resolve, reject) => {
    window.addEventListener('NEOLine.N3.EVENT.READY', () => {
      const neolineN3 = new window.NEOLineN3.Init();
      if (neolineN3) {
        resolve(neolineN3);
      } else {
        reject(new Error('N3 dAPI method failed to load.'));
      }
    });
  });

  await initN3Dapi;
  neoline = new NEOLineN3.Init();

  addNeoLineEventListeners();

  await checkNet();
  const data = await queryState();

  setInterval(async () => {
    const balances = await getBalance();
    store.commit('blockchain/setWalletBalance', balances);
  }, (10 * 1000));

  return data;
}

async function invokeMulti(invocations, allowedContracts, mintAmount = 1) {
  await checkConnection();
  const invokeScript = {
    invokeArgs: invocations,
    fee: '0.0000',
    extraSystemFee: (0.00507 + (mintAmount - 1) * 0.18).toString(),
    signers: [
      {
        account: wallet.hash,
        scopes: 16,
        allowedContracts,
      },
    ],
  };

  const data = await neoline.invokeMultiple(invokeScript);

  return data.txid;
}

async function transfer(toAddress, asset, amount) {
  await checkConnection();
  return neoline.send({
    fromAddress: wallet.address,
    toAddress,
    asset,
    amount,
    fee: '0.0001',
    broadcastOverride: false,
  });
}

export default {
  init,
  connect,
  disconnect,
  invokeMulti,
  queryState,
  getBalance,
  transfer,
};
