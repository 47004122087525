import { createApp } from 'vue';
import VueToast from 'vue-toast-notification';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@/os/style/index.css';
import '@/os/style/animations.css';

import walletPlugin from '@/os/plugins/walletPlugin';
import osPlugin from '@/os/plugins/osPlugin';
import contractPlugin from '@/os/plugins/contractPlugin';
import pricePlugin from '@/os/plugins/pricePlugin';
import utilsPlugin from '@/os/plugins/utilsPlugin';

import BaseModal from '@/os/components/modals/BaseModal.vue';
import SimpleModal from '@/os/components/modals/SimpleModal.vue';
import NudesSelect from '@/os/components/basic/NudesSelect.vue';
import FlmTooltip from '@/os/components/basic/FlmTooltip.vue';

import clickOutsideDirective from '@/os/directives/clickOutside';
import VueLazyLoad from 'vue3-lazyload';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueToast, { position: 'top' });

app.use(walletPlugin);
app.use(osPlugin);
app.use(contractPlugin);
app.use(pricePlugin);
app.use(utilsPlugin);
app.use(VueLazyLoad);

app.directive('click-outside', clickOutsideDirective);

app.component('base-modal', BaseModal);
app.component('simple-modal', SimpleModal);
app.component('nudes-select', NudesSelect);
app.component('flm-tooltip', FlmTooltip);

const vm = app.mount('#app');
window.toast = vm.$toast;
