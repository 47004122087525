/* eslint-disable no-await-in-loop,no-restricted-syntax */
import { sc } from '@cityofzion/neon-js';
import store from '@/store';
import contractAPI from '@/os/APIs/contractAPI';
import priceAPI from '@/os/APIs/priceAPI';
import rpcAPI from '@/os/APIs/rpcAPI';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import tokens from '@/os/values/tokens';
import { CHAIN_ID_NEO } from '@/os/values/chains';
import { FLUND_FLM_PR_YEAR } from '@/os/values/settings';

const pools = contractAPI.getAllPools();

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

async function getInternalPrice(pool, chainData) {
  let completed = false;
  const token1Supply = new BigNumber(chainData.tokenSupply[0].value).shiftedBy(-pool.tokens[0].decimals);
  const token2Supply = new BigNumber(chainData.tokenSupply[1].value).shiftedBy(-pool.tokens[1].decimals);
  if (['bNEO', 'FLM', 'USDT'].includes(pool.tokens[0].symbol)) {
    const xToken = `${pool.tokens[1].symbol}USDT`;
    const price = priceAPI.tokenPrices.value[`${pool.tokens[0].symbol}USDT`];
    const tokenXprice = (token1Supply.dividedBy(token2Supply)).multipliedBy(price)
      .toNumber();
    completed = true;
    await store.commit('prices/setInternalPrice', {
      symbol: xToken,
      price: tokenXprice,
    });
  }

  if (!completed && ['bNEO', 'FLM', 'USDT'].includes(pool.tokens[1].symbol)) {
    const xToken = `${pool.tokens[0].symbol}USDT`;
    const price = priceAPI.tokenPrices.value[`${pool.tokens[1].symbol}USDT`];
    const tokenXprice = (token2Supply.dividedBy(token1Supply)).multipliedBy(price)
      .toNumber();
    await store.commit('prices/setInternalPrice', {
      symbol: xToken,
      price: tokenXprice,
    });
  }
}

function stackInvokes(pool) {
  const stakingHash = pool.reversePool ? contractAPI.getContractByName('reverseStaking').hash : contractAPI.getContractByName('staking').hash;
  const data = [];
  data.push(sc.createScript({
    scriptHash: contractAPI.getContractByName(pool.symbol).hash,
    operation: 'getReserves',
    args: [],
  }));
  data.push(sc.createScript({
    scriptHash: stakingHash,
    operation: 'getCurrentTotalAmount',
    args: [
      {
        type: 'Hash160',
        value: contractAPI.getContractByName(pool.symbol).hash,
      },
    ],
  }));
  data.push(sc.createScript({
    scriptHash: contractAPI.getContractByName(pool.symbol).hash,
    operation: 'totalSupply',
    args: [],
  }));
  return data;
}

async function formatPoolInvokes(stackedScript) {
  const blockChainData = await rpcAPI.runRawScript(stackedScript);
  const poolData = {};
  let i = 0;
  for (const pool of Object.values(pools)) {
    poolData[pool.symbol] = {
      tokenSupply: blockChainData[i].value,
      totalLPStaked: blockChainData[i + 1].value,
      totalLPSupply: blockChainData[i + 2].value,
    };
    i += 3;
  }
  return poolData;
}

// eslint-disable-next-line no-unused-vars
async function getFlundData(fees) {
  const data = [];
  data.push(sc.createScript({
    scriptHash: contractAPI.getContractByName('flund').hash,
    operation: 'checkCurrentFLMProfit',
    args: [],
  }));
  for (const token of Object.values(tokens)) {
    if (token.chainId === CHAIN_ID_NEO) {
      const args = token.symbol !== 'FLUND' ? [{
        type: 'Hash160',
        value: contractAPI.getContractByName(token.symbol).hash,
      }] : [];
      data.push(sc.createScript({
        scriptHash: contractAPI.getContractByName('flund').hash,
        operation: token.symbol !== 'FLUND' ? 'getContractAssetBalance' : 'totalSupply',
        args,
      }));
    }
  }
  const flundChainData = await rpcAPI.runRawScript(data);
  const flundData = {
    tokens: {},
    flundTotalUsdValue: 0,
    flundTotalFlmValue: 0,
    flundUsdNotConverted: 0,
    flundApy: 0,
    flundFlmPrSec: 0,
    flundUsdPrSec: 0,
    flundUnclaimed: new BigNumber(flundChainData[0].value).shiftedBy(-8)
      .toNumber(),
  };

  const flmPrice = priceAPI.getPrice('FLM');

  let i = 1;
  for (const token of Object.values(tokens)) {
    if (token.chainId === CHAIN_ID_NEO) {
      if (token.symbol !== 'FLUND') {
        flundData.tokens[token.symbol] = new BigNumber(flundChainData[i].value).shiftedBy(-token.decimals)
          .toNumber();
        flundData.flundTotalUsdValue += flundData.tokens[token.symbol] * priceAPI.getPrice(token.symbol);
        if (token.symbol !== 'FLM') {
          flundData.flundUsdNotConverted += flundData.tokens[token.symbol] * priceAPI.getPrice(token.symbol);
        }
      }
      if (token.symbol === 'FLUND') {
        flundData.flundTokens = new BigNumber(flundChainData[i].value).shiftedBy(-token.decimals)
          .toNumber();
      }
      i += 1;
    }
  }
  flundData.flundTotalFlmValue = new BigNumber(flundData.flundTotalUsdValue).dividedBy(flmPrice)
    .toNumber();

  flundData.flundTokenPrice = new BigNumber(flundData.flundTotalUsdValue).dividedBy(flundData.flundTokens)
    .toNumber();

  flundData.flundApy = new BigNumber(FLUND_FLM_PR_YEAR).multipliedBy(flmPrice)
    .plus(fees)
    .dividedBy(flundData.flundTotalUsdValue)
    .times(100)
    .toNumber();

  flundData.flundMintFlmPrSec = new BigNumber(FLUND_FLM_PR_YEAR).dividedBy(365 * 86400).toNumber();
  flundData.flundMintUsdPrSec = new BigNumber(FLUND_FLM_PR_YEAR).dividedBy(365 * 86400).multipliedBy(flmPrice).toNumber();

  flundData.flundFeeFlmPrSec = new BigNumber(fees).dividedBy(365 * 86400).dividedBy(flmPrice).toNumber();
  flundData.flundFeeUsdPrSec = new BigNumber(fees).dividedBy(365 * 86400).toNumber();

  flundData.flundFlmPrSec = new BigNumber(flundData.flundTotalFlmValue).multipliedBy(new BigNumber(flundData.flundApy).dividedBy(100))
    .dividedBy(365 * 86400)
    .toNumber();

  flundData.flundUsdPrSec = new BigNumber(flundData.flundTotalFlmValue).multipliedBy(new BigNumber(flundData.flundApy).dividedBy(100))
    .dividedBy(365 * 86400)
    .multipliedBy(flmPrice)
    .toNumber();

  await store.commit('prices/setInternalPrice', {
    symbol: 'FLUNDUSDT',
    price: flundData.flundTokenPrice,
  });
  const stats = await api.get('/analytics/flund-stats');
  const history = await api.get('/project-info/flund-history?page=0&limit=30');
  flundData.stats = stats.data;
  flundData.history = history.data.recent_earnings;
  store.commit('blockchain/setFlundData', flundData);
}

function getFeeApy(feeHistoryData) {
  const result = {
    flundFees: 0,
  };
  Object.keys(feeHistoryData)
    .forEach((key) => {
      let totalAPY = 0;
      let trades = 0;
      if (typeof feeHistoryData[key] === 'undefined') {
        result[key] = {
          feeApr: 0,
          trades: 0,
        };
      }
      if (typeof feeHistoryData[key] !== 'undefined') {
        Object.values(feeHistoryData[key])
          .forEach((p) => {
            totalAPY += (new BigNumber(p.fee).dividedBy(p.lq)).multipliedBy(100)
              .toNumber();
            trades += p.tx;
            result.flundFees += new BigNumber(p.fee).dividedBy(6)
              .toNumber();
          });
      }
      result[key] = {
        feeApr: (new BigNumber(totalAPY).dividedBy(feeHistoryData[key].length)).multipliedBy(365)
          .toNumber(),
        trades,
      };
    });
  result.flundFees = new BigNumber(result.flundFees).dividedBy(feeHistoryData['FLP-bNEO-GAS'].length)
    .multipliedBy(365)
    .toNumber();
  return result;
}

export default async function blockchainData() {
  let gasPrice = priceAPI.getPrice('GAS');
  while (gasPrice === 0.00) {
    await new Promise((r) => setTimeout(r, 100));
    gasPrice = priceAPI.getPrice('GAS');
  }

  const history = await api.get('/analytics/pool-stats?interval=days&mainnet=true');
  const allHistory = getFeeApy(history.data);
  const result = [];
  const scripts = [];

  for (const pool of Object.values(pools)) {
    scripts.push(...stackInvokes(pool));
  }

  const poolData = await formatPoolInvokes(scripts);

  for (const pool of Object.values(pools)) {
    const token1 = { ...pool.tokens[0] };
    const token2 = { ...pool.tokens[1] };

    token1.supply = new BigNumber(poolData[pool.symbol].tokenSupply[0].value).shiftedBy(-token1.decimals)
      .toNumber();
    token2.supply = new BigNumber(poolData[pool.symbol].tokenSupply[1].value).shiftedBy(-token2.decimals)
      .toNumber();

    if (pool.internalPrice) {
      await getInternalPrice(pool, poolData[pool.symbol]);
    }

    token1.btcPrice = priceAPI.getPrice(token1.symbol, 'BTC');
    token2.btcPrice = priceAPI.getPrice(token2.symbol, 'BTC');
    token1.usdPrice = priceAPI.getPrice(token1.symbol);
    token2.usdPrice = priceAPI.getPrice(token2.symbol);

    token1.totalValue = new BigNumber(token1.supply).multipliedBy(token1.usdPrice)
      .toNumber();
    token2.totalValue = new BigNumber(token2.supply).multipliedBy(token2.usdPrice)
      .toNumber();

    pool.totalLpStaked = new BigNumber(poolData[pool.symbol].totalLPStaked).shiftedBy(-pool.decimals)
      .toNumber();
    pool.totalLpMinted = new BigNumber(poolData[pool.symbol].totalLPSupply).shiftedBy(-pool.decimals)
      .toNumber();

    pool.lpTokenUsdPrice = (token1.totalValue + token2.totalValue) / pool.totalLpMinted;
    pool.totalUsdvalue = pool.lpTokenUsdPrice * pool.totalLpMinted;
    pool.stakedUsdValue = pool.lpTokenUsdPrice * pool.totalLpStaked;

    const mintPrDay = new BigNumber(pool.mintPrSec).multipliedBy(86400);

    const xTokenPrice = priceAPI.getPrice(pool.rewardToken.symbol);

    pool.tokenRewardPrAsset = mintPrDay.dividedBy(pool.totalLpStaked)
      .toNumber();
    pool.dailyUsdPrAsset = (mintPrDay.dividedBy(pool.totalLpStaked)).multipliedBy(xTokenPrice)
      .toNumber();
    pool.dailyYield = (mintPrDay.multipliedBy(xTokenPrice)).dividedBy(pool.stakedUsdValue)
      .toNumber();

    pool.feeApr = Object.keys(allHistory)
      .includes(pool.symbol) ? allHistory[pool.symbol].feeApr : 0;
    pool.MonthTxCount = Object.keys(allHistory)
      .includes(pool.symbol) ? allHistory[pool.symbol].trades : 0;

    if (pool.totalLpStaked > 0) {
      pool.apr = (new BigNumber(pool.dailyYield).multipliedBy(365)).multipliedBy(100)
        .toNumber();
    } else {
      pool.apr = 0;
    }

    pool.tokens = [token1, token2];

    result.push(pool);
  }
  store.commit('blockchain/setPoolData', result);
  await getFlundData(allHistory.flundFees);
  return result;
}
