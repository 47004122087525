import walletAPI from '@/os/APIs/walletAPI';
import blockchainData from '@/os/externalData/blockchainData';
import userData from '@/os/externalData/userData';
import swap from '@/os/invocations/swapping';
import claim from '@/os/invocations/claiming';
import staking from '@/os/invocations/staking';
import wrapping from '@/os/invocations/wrapping';
import axios from 'axios';

async function getDeadline(deadline) {
  const nowTime = await axios.get('https://api.flamingo.finance/timestamp');
  return parseInt(parseInt(nowTime.data * 1000, 10) + parseFloat(deadline), 10);
}

async function claimPools(pools) {
  if (!walletAPI.state.conditions.value.poolData) {
    await this.getUserPoolData();
  }
  const invokeConfig = await claim.claimAllPools(pools);

  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function wrapNEO(amount) {
  const invokeConfig = wrapping.toBurgerToken(amount);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function unwrapNEO(amount) {
  const invokeConfig = wrapping.fromBurgerToken(amount);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function swapIn(routes, amountIn, amountOutMin, deadline) {
  const invokeConfig = swap.swapIn(routes, amountIn, amountOutMin, deadline);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function swapOut(routes, amountOut, amountInMax, deadline) {
  const invokeConfig = swap.swapOut(routes, amountOut, amountInMax, deadline);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, deadline) {
  const invokeConfig = swap.addLiquidity(tokenA, tokenB, amountADesired, amountBDesired, amountAMin, amountBMin, deadline);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function removeLiquidity(tokenA, tokenB, liquidityToRemove, amountAMin, amountBMin, deadline) {
  const invokeConfig = swap.removeLiquidity(tokenA, tokenB, liquidityToRemove, amountAMin, amountBMin, deadline);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function stake(amount, pool) {
  const invokeConfig = staking.stakeLiquidityPoolToken(amount, pool);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function unstake(amount, pool) {
  const invokeConfig = staking.unStakeLiquidityPoolToken(amount, pool);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function lock(assetHash, toChainId, toAddress, amount) {
  const invokeConfig = await wrapping.lock(assetHash, toChainId, toAddress, amount);
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function unlock({ args }) {
  const invokeConfig = await wrapping.unlock({ args });
  return walletAPI.invokeWallet(invokeConfig.invocations, invokeConfig.allowedContracts);
}

async function getPoolData() {
  return blockchainData();
}

async function getUserPoolData() {
  return userData();
}

export default {
  claimPools,
  wrapNEO,
  unwrapNEO,
  swapIn,
  swapOut,
  addLiquidity,
  removeLiquidity,
  stake,
  unstake,
  getPoolData,
  getUserPoolData,
  lock,
  unlock,
  getDeadline,
};
