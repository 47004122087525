import tokens from './tokens';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

const testnetPools = {
  'FLP-FLM-fUSDT': {
    symbol: 'FLP-FLM-fUSDT',
    decimals: 8,
    hash: '0xe9a0a6e48ce5f78155229e7e581a40fbf46499b4',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.FLM, tokens.fUSDT],
    mintPrSec: 0.0005,
  },
  'FLP-fWBTC-fUSDT': {
    symbol: 'FLP-fWBTC-fUSDT',
    decimals: 8,
    hash: '0x97a50f9a2eb7f6fffd5809570fa0884d2a23e950',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fUSDT, tokens.fWBTC],
    mintPrSec: 0.002,
  },
  'FLP-bNEO-fUSDT': {
    symbol: 'FLP-bNEO-fUSDT',
    decimals: 8,
    hash: '0x38f5f6cb98e623bdd1c996f939459f63edbb6ebb',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.fUSDT],
    mintPrSec: 0.0005,
  },
  'FLP-bNEO-GAS': {
    symbol: 'FLP-bNEO-GAS',
    decimals: 8,
    hash: '0x9f882cb625c7312f899bff70098f4929fc5b9245',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.GAS],
    mintPrSec: 0.5,
  },
  'FLP-fWETH-fUSDT': {
    symbol: 'FLP-fWETH-fUSDT',
    decimals: 8,
    hash: '0x309c895938b1144e401bd1f96238d00dc8b4d5da',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fWETH, tokens.fUSDT],
    mintPrSec: 0.5,
  },
  'FLP-FLM-SWTH': {
    symbol: 'FLP-FLM-SWTH',
    decimals: 8,
    hash: '0xe83e24762f174c055f5f84994b65fbf3f74972df',
    internalPrice: true,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.FLM, tokens.SWTH],
    mintPrSec: 0.5,
  },
  'FLP-FLM-GM': {
    symbol: 'FLP-FLM-GM',
    decimals: 8,
    hash: '0x5532178b1e605854e4897274f4c4a1f9fdc6b198',
    internalPrice: true,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.GM, tokens.FLM],
    mintPrSec: 0.5,
  },
  'FLP-FLM-fCAKE': {
    symbol: 'FLP-FLM-fCAKE',
    decimals: 8,
    hash: '0x4f1a2a494b0409e8152a2b201672e0af19c6502c',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.FLM, tokens.fCAKE],
    mintPrSec: 0.5,
  },
  'FLP-FLM-NUDES': {
    symbol: 'FLP-FLM-NUDES',
    decimals: 8,
    hash: '0x48bb6885f25552c69a19fc3e3284114e5356e63d',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.NUDES,
    tokens: [tokens.FLM, tokens.NUDES],
    mintPrSec: 0.4,
  },
  'FLP-FLM-CANDY': {
    symbol: 'FLP-FLM-CANDY',
    decimals: 8,
    hash: '0x22af81b3efd26820b0283439f17d66d0c2e36e1d',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.CANDY,
    tokens: [tokens.FLM, tokens.CANDY],
    mintPrSec: 0.2,
  },
  'FLP-FLM-LRB': {
    symbol: 'FLP-FLM-LRB',
    decimals: 8,
    hash: '0x4823d663a51b6a4906bf349ec314a8563a4036e1',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.LRB,
    tokens: [tokens.FLM, tokens.LRB],
    mintPrSec: 0.0000317,
  },
  'FLP-FLM-LUSD': {
    symbol: 'FLP-FLM-LUSD',
    decimals: 8,
    hash: '0xc7acb3e28cb4db52f216861160ff612894aab2b3',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.LRB,
    tokens: [tokens.USDL, tokens.FLM],
    mintPrSec: 0.0000317,
  },
  'FRP-FLM-DOGEF': {
    symbol: 'FRP-FLM-DOGEF',
    decimals: 8,
    hash: '0x7a92ee61ca9bef6dd1bf1898291e53d4d039cfd8',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.DOGEF,
    tokens: [tokens.FLM, tokens.DOGEF],
    mintPrSec: 221968.54388635,
  },
  'FRP-FLM-DOGER': {
    symbol: 'FRP-FLM-DOGER',
    decimals: 8,
    hash: '0xc7696781d3be9dae9bd4df8d8c249bd383a63a7d',
    internalPrice: true,
    reversePool: true,
    rewardToken: tokens.DOGER,
    tokens: [tokens.FLM, tokens.DOGER],
    mintPrSec: 221968.54388635,
  },
};

const mainnetPools = {
  'FLP-FLM-bNEO': {
    symbol: 'FLP-FLM-bNEO',
    decimals: 8,
    hash: '0x4d5a85b0c83777df72cfb665a933970e4e20c0ec',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.FLM],
    mintPrSec: 1.2524801587,
  },
  'FLP-FLM-fUSDT': {
    symbol: 'FLP-FLM-fUSDT',
    decimals: 8,
    hash: '0x59aa80468a120fe79aa5601de07746275c9ed76a',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fUSDT, tokens.FLM],
    mintPrSec: 0.2066798942,
  },
  'FLP-FLM-fWBTC': {
    symbol: 'FLP-FLM-fWBTC',
    decimals: 8,
    hash: '0x6bcbf09a7193c968d608178a45785967f0721c42',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fWBTC, tokens.FLM],
    mintPrSec: 0.2066798942,
  },
  'FLP-FLM-fWETH': {
    symbol: 'FLP-FLM-fWETH',
    decimals: 8,
    hash: '0x1404929a660620869c9cb46ff228ee9d7147959d',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fWETH, tokens.FLM],
    mintPrSec: 0.2066798942,
  },
  'FLP-FLM-GAS': {
    symbol: 'FLP-FLM-GAS',
    decimals: 8,
    hash: '0x171d791c0301c332cfe95c6371ee32965e34b606',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.GAS, tokens.FLM],
    mintPrSec: 0.2066798942,
  },
  'FLP-FLM-pONT': {
    symbol: 'FLP-FLM-pONT',
    decimals: 8,
    hash: '0x1b3f740240af479f07e44ee3ee78df4c6cb4b1fb',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.pONT, tokens.FLM],
    mintPrSec: 0.0330687831,
  },
  'FLP-FLM-WING': {
    symbol: 'FLP-FLM-WING',
    decimals: 8,
    hash: '0x576f42660a266141f03972f96992f2c1c10253a0',
    internalPrice: true,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.pWING, tokens.FLM],
    mintPrSec: 0.0206679894,
  },
  'FLP-FLM-GM': {
    symbol: 'FLP-FLM-GM',
    decimals: 8,
    hash: '0xf23221a92c29beffbea6e46c681c8380d9794579',
    internalPrice: true,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.GM, tokens.FLM],
    mintPrSec: 0.0330687831,
  },
  'FLP-FLM-fCAKE': {
    symbol: 'FLP-FLM-fCAKE',
    decimals: 8,
    hash: '0x236a6679dc26b5f11fae7c3b30784509216dd4b0',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fCAKE, tokens.FLM],
    mintPrSec: 0.1033399471,
  },
  'FLP-FLM-SWTH': {
    symbol: 'FLP-FLM-SWTH',
    decimals: 8,
    hash: '0xd8788aab4f7d84384f1808f9aaacd5dc4ea94317',
    internalPrice: true,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.SWTH, tokens.FLM],
    mintPrSec: 0.0537367725,
  },
  'FLP-bNEO-fUSDT': {
    symbol: 'FLP-bNEO-fUSDT',
    decimals: 8,
    hash: '0x545dee8354823d1bdf4ac524e4092f7405025247',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.fUSDT],
    mintPrSec: 0.2066798942,
  },
  'FLP-bNEO-fWBTC': {
    symbol: 'FLP-bNEO-fWBTC',
    decimals: 8,
    hash: '0xc777a8032c1d9d7b885c7357d4c93e7a39f93942',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.fWBTC],
    mintPrSec: 0.2066798942,
  },
  'FLP-bNEO-fWETH': {
    symbol: 'FLP-bNEO-fWETH',
    decimals: 8,
    hash: '0xedcbe55b04bcc7dad69cfe243bf3d26dc106a1d4',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.fWETH],
    mintPrSec: 0.2066798942,
  },
  'FLP-bNEO-GAS': {
    symbol: 'FLP-bNEO-GAS',
    decimals: 8,
    hash: '0x3244fcadcccff190c329f7b3083e4da2af60fbce',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.bNEO, tokens.GAS],
    mintPrSec: 0.1570767196,
  },
  'FLP-fWBTC-fUSDT': {
    symbol: 'FLP-fWBTC-fUSDT',
    decimals: 8,
    hash: '0x45d182227b5d753c7f358594b631838b92caf409',
    internalPrice: false,
    reversePool: false,
    rewardToken: tokens.FLM,
    tokens: [tokens.fUSDT, tokens.fWBTC],
    mintPrSec: 0.2066798942,
  },
  'FRP-FLM-NUDES': {
    symbol: 'FRP-FLM-NUDES',
    decimals: 8,
    hash: '0x35de27f4d7bc356c9a4b734cabb38cc63657233f',
    internalPrice: true,
    reversePool: true,
    rewardTokenHomepage: 'https://nudes.army/',
    rewardToken: tokens.NUDES,
    tokens: [tokens.NUDES, tokens.FLM],
    mintPrSec: 4756468.79756469,
  },
  'FRP-FLM-CANDY': {
    symbol: 'FRP-FLM-CANDY',
    decimals: 8,
    hash: '0xf9956798ca7e8274f7ab4f1f6d6c06f55a0a9bd3',
    internalPrice: true,
    reversePool: true,
    rewardTokenHomepage: 'https://neocandy.io/',
    rewardToken: tokens.CANDY,
    tokens: [tokens.CANDY, tokens.FLM],
    mintPrSec: 5.707762557,
  },
  'FRP-FLM-DOGEF': {
    symbol: 'FRP-FLM-DOGEF',
    decimals: 8,
    hash: '0x5d8545d1780190e1bf7605713c901b197bcfaf11',
    internalPrice: true,
    reversePool: true,
    rewardTokenHomepage: 'https://dogerift.com/',
    rewardToken: tokens.DOGEF,
    tokens: [tokens.DOGEF, tokens.FLM],
    mintPrSec: 221968.54388635,
  },
  'FRP-FLM-DOGER': {
    symbol: 'FRP-FLM-DOGER',
    decimals: 8,
    hash: '0xb3fb4160534d8c366c06d31fc8df7bc2b3428785',
    internalPrice: true,
    reversePool: true,
    rewardTokenHomepage: 'https://dogerift.com/',
    rewardToken: tokens.DOGER,
    tokens: [tokens.DOGER, tokens.FLM],
    mintPrSec: 221968.54388635,
  },
  'FRP-FLM-LRB': {
    symbol: 'FRP-FLM-LRB',
    decimals: 8,
    hash: '0x1f86bab6d548a3bd4e9292be0937c074da78ab77',
    internalPrice: true,
    reversePool: true,
    rewardTokenHomepage: 'https://lyrebird.finance/',
    rewardToken: tokens.LRB,
    tokens: [tokens.LRB, tokens.FLM],
    mintPrSec: 0.04755993,
  },
};

const pools = TARGET_MAINNET ? mainnetPools : testnetPools;

export default {
  ...pools,
};
