<template>
    <div v-if="$os.state.activeModal.value === modalName" class="elementToFadeInAndOut fixed h-full inset-0 simpleModal flex justify-center items-center" style="z-index: 1000;">
      <div
        class="modal mx-auto text-white rounded-2xl shadow-lg relative mb-12 z-10"
        :style="'height: ' + height + 'px; width: ' + width + 'px'"
      >
        <button
          class="absolute top-4 right-4 outline-none md:hidden"
          :class="popOut"
          @click="$os.triggerModal()"
        >
          <i class="fas fa-times"></i>
        </button>
        <slot></slot>
      </div>
      <div @click="$os.triggerModal()" class="absolute inset-0 w-full h-full"></div>
    </div>
</template>

<script>
export default {
  name: 'modal',
  props: [
    'height',
    'width',
    'modalName',
  ],
  setup() {
    const popOut = 'hover:text-flm-pink transition duration-500 ease-in-out transform hover:scale-110';
    return {
      popOut,
    };
  },
};
</script>

<style scoped>

.modal {
  background: #1F1A22;
  box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.simpleModal {
  background: rgba(17, 13, 17, 0.8);
  backdrop-filter: blur(8px);
}
</style>
