export default ({
  namespaced: true,
  state: () => ({
    memeList: [],
  }),
  mutations: {
    setMemeList(state, data) {
      state.memeList = data;
    },
  },
  actions: {},
  modules: {},
});
