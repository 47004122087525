<template>
  <simple-modal
    width="512"
    modalName="ConnectWallet"
  >
    <div
      class="flex absolute inset-0 items-center justify-center"
      v-if="loading"
    >
      <div>
        <spinner></spinner>
        <div>Connecting...</div>
      </div>
    </div>
    <div
      class="text-white px-8 py-5 tracking-[0.02em]"
      v-if="!loading"
    >
      <div class=" text-4xl font-bold connectWalletTitle">Connect Wallet</div>
      <div class="text-lg mt-2">Please connect your Neo N3 Wallet to proceed.
      </div>
      <div class="text-lg">Haven’t got a wallet? Read more about it
        <a
          href="https://neo.org"
          target="_blank"
          class="underline"
        > here!</a>
      </div>
      <div class="flex flex-col justify-center mt-3">
        <button
          class="wallet-button"
          @click="connect('NEOLINE')"
        >
          <div class="flex items-center">
            <img :src="require('../../../../public/img/connectWallet/neoline.svg')">
            <div class="ml-2.5 text-lg font-medium">NeoLine</div>
          </div>
          <img src="img/ui/arrow-right.svg" width="24" height="24" />
        </button>
        <button
          class="wallet-button"
          @click="connect('O3')"
        >
          <div class="flex items-center">
            <img :src="require('../../../../public/img/connectWallet/o3.svg')">
            <div class="ml-2.5 text-lg font-medium">O3</div>
          </div>
          <img src="img/ui/arrow-right.svg" width="24" height="24" />
        </button>
        <button
          class="wallet-button"
          @click="connect('WalletConnect')"
        >
          <div class="flex items-center">
            <img :src="require('../../../../public/img/connectWallet/neon.svg')">
            <div class="ml-2.5 text-lg font-medium">NEON</div>
          </div>
          <img src="img/ui/arrow-right.svg" width="24" height="24" />
        </button>
        <button
          class="wallet-button"
          @click="connect('OneGate')"
        >
          <div class="flex items-center">
            <img :src="require('../../../../public/img/connectWallet/onegate.svg')">
            <div class="ml-2.5 text-lg font-medium">OneGate</div>
          </div>
          <img src="img/ui/arrow-right.svg" width="24" height="24" />
        </button>
      </div>
    </div>
  </simple-modal>
</template>

<script>
/* eslint-disable no-multi-assign */
import { useStore } from 'vuex';
import { ref } from 'vue';
import osAPI from '@/os/APIs/osAPI';
import walletAPI from '@/os/APIs/walletAPI';
import Spinner from '@/os/components/Spinner.vue';
import { WrongNetError } from '@/os/blockchain/wallet/walletUtilities';

export default {
  name: 'connectWallet',
  components: {
    Spinner,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);

    async function connect(walletName) {
      loading.value = true;
      store.commit('blockchain/initializeConnection');

      try {
        await walletAPI.connectWallet(walletName);
      } catch (error) {
        if (error instanceof WrongNetError) {
          store.commit('blockchain/clearWallet');
        }
      } finally {
        loading.value = false;
        osAPI.triggerModal();
      }
    }

    return {
      connect,
      loading,
    };
  },
};
</script>

<style scoped>
.wallet-button {
  height: 56px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  margin-top: 12px;
}

.wallet-button:active {
  opacity: 0.75;
}

.connectWalletTitle {
  letter-spacing: 0.02em;
  background: linear-gradient(134.69deg, #C63BC9 0%, #642FFA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  mix-blend-mode: normal;
}

</style>
