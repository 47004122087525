import {
  CHAIN_ID_ETHEREUM,
  CHAIN_ID_ONTOLOGY,
  CHAIN_ID_NEO,
  CHAIN_ID_BSC,
} from '@/os/values/chains';

const APIS = {
  [CHAIN_ID_ETHEREUM]: () => import('./ethereum'),
  [CHAIN_ID_ONTOLOGY]: () => import('./ontology'),
  [CHAIN_ID_NEO]: () => import('./neo'),
  [CHAIN_ID_BSC]: () => import('./binance'),
};

export async function getChainApi(chainId) {
  if (!APIS[chainId]) {
    throw new Error('Chain is not supported');
  }
  return (await APIS[chainId]()).default;
}

export default {
  getChainApi,
};
