import { computed } from 'vue';
import store from '@/store';

const state = {
  prices: computed(() => store.state.prices.conditions.prices),
};

const tokenPrices = computed(() => store.state.prices.tokens);

export default {
  tokenPrices,
  state,
};
