import { WcSdk } from '@cityofzion/wallet-connect-sdk-core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CLIENT_EVENTS } from '@walletconnect/client';
import Neon from '@cityofzion/neon-js';
import store from '@/store';
import contractAPI from '@/os/APIs/contractAPI';
import walletUtilities from './walletUtilities';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';
let wcClient = false;
let session;
let walletInfo = {};
let walletAddress;
let neonConnectWindow;

async function getBalance() {
  const client = Neon.create.rpcClient(TARGET_MAINNET ? 'https://mainnet3.neo.coz.io:443' : 'https://testnet1.neo.coz.io:443');
  const balances = await client.getNep17Balances(walletAddress)
    .catch((error) => {
      walletUtilities.errorHandler(error.type, 'WALLET');
    });
  /* eslint-disable no-param-reassign */
  balances.balance.forEach((item) => {
    const tokenInfo = contractAPI.getContractByHash(item.assethash);
    item.symbol = tokenInfo ? tokenInfo.symbol : '';
    item.amount = item.amount > 0 ? item.amount / (10 ** (tokenInfo ? tokenInfo.decimals : 8)) : item.amount;
    item.contract = item.assethash;
  });
  return balances.balance;
}

async function disconnect() {
  localStorage.removeItem('lastWallet');
  await store.commit('blockchain/clearWallet');
  wcClient = await WcSdk.initClient(
    '', // logger: use debug to show all log information on browser console
    'wss://relay.walletconnect.org', // relay server
  );
  session = await WcSdk.getSession(wcClient);
  if (session) {
    // eslint-disable-next-line no-console
    console.log('disconnecting with WcSdk from session', session);
    await WcSdk.disconnect(wcClient, session);
  }
}

async function checkSession() {
  session = await WcSdk.getSession(wcClient);
  if (session) {
    walletAddress = WcSdk.getAccountAddress(session);
    walletInfo = {
      type: 'WalletConnect',
      address: walletAddress,
      balances: [],
      hash: walletUtilities.addressToHash(walletAddress),
      label: session.peer.metadata.name, // Wallet name eg. Neon Wallet
      connected: true,
      mainNet: TARGET_MAINNET,
    };

    localStorage.setItem('lastWallet', JSON.stringify(walletInfo));
    store.commit('blockchain/setWalletData', walletInfo);

    // addUpdateBalanceInterval();
  }
}

function addWcSdkEventListener() {
  // session created on wallet
  wcClient.on(
    CLIENT_EVENTS.session.created,
    async () => {
      neonConnectWindow.close();
      window.focus();
      checkSession();
    },
  );

  // session deleted on wallet
  wcClient.on(
    CLIENT_EVENTS.session.deleted,
    async () => {
      disconnect();
    },
  );
}

async function init() {
  store.commit('blockchain/initializeConnection');

  wcClient = await WcSdk.initClient(
    '', // logger: use debug to show all log information on browser console
    'wss://relay.walletconnect.org', // relay server
  );

  addWcSdkEventListener();

  checkSession();
}

async function connect() {
  if (!wcClient) await init();
  if (!session) {
    WcSdk.subscribeToEvents(wcClient, {
      onProposal: (uri) => {
        neonConnectWindow = window.open(`https://neon.coz.io/connect?uri=${uri}`, '_blank');
        neonConnectWindow.focus();
      },
    });

    await WcSdk.connect(wcClient, {
      chains: [TARGET_MAINNET ? 'neo3:mainnet' : 'neo3:testnet'], // blockchain and network identifier
      methods: ['invokeFunction'], // which RPC methods do you plan to call
      appMetadata: {
        name: 'DogeRift', // your application name to be displayed on the wallet
        description: 'Bored Doge NFTs', // description to be shown on the wallet
        url: 'https://market.dogerift.com/', // url to be linked on the wallet
        icons: ['https://market.dogerift.com/favicon.svg'], // icon to be shown on the wallet
      },
    });
    await checkSession();
  }
}

async function invokeMulti(invocations, allowedContracts) {
  if (!session) return;

  const invokeScript = {
    invocations,
    signers: [
      {
        scopes: 16,
        allowedContracts,
      },
    ],
  };

  const resp = await WcSdk.invokeFunction(wcClient, session, TARGET_MAINNET ? 'neo3:mainnet' : 'neo3:testnet', invokeScript)
    .catch((error) => {
      walletUtilities.errorHandler(error.type, 'WALLET');
    });
  // eslint-disable-next-line consistent-return
  return resp.result;
}

export default {
  init,
  connect,
  disconnect,
  getBalance,
  invokeMulti,
};
