import walletAPI from '@/os/APIs/walletAPI';
import contractAPI from '@/os/APIs/contractAPI';
import InvokeConfig from '@/os/utils/invoke';

function stakeLiquidityPoolToken(amount, pool) {
  const poolData = contractAPI.getContractByName(pool);
  let allowedContracts = [
    contractAPI.getContractByName('staking').hash,
    poolData.hash,
  ];
  let invocation = [{
    scriptHash: poolData.hash,
    operation: 'transfer',
    args: [
      {
        type: 'Address', // sender
        value: walletAPI.state.walletData.value.address,
      },
      {
        type: 'Hash160', // tokenB
        value: contractAPI.getContractByName('staking').hash,
      },
      {
        type: 'Integer', // amountADesired
        value: amount,
      },
      {
        type: 'Any', // amountADesired
        value: '',
      },
    ],
  }];
  if (poolData.reversePool) {
    allowedContracts = [
      poolData.hash,
      contractAPI.getContractByName('reverseStaking').hash,
    ];
    invocation = [{
      scriptHash: contractAPI.getContractByName('reverseStaking').hash,
      operation: 'deposit',
      args: [
        {
          type: 'Hash160', // tokenB
          value: poolData.hash,
        },
        {
          type: 'Address', // sender
          value: walletAPI.state.walletData.value.address,
        },
        {
          type: 'Integer', // amountADesired
          value: amount,
        },
      ],
    }];
  }

  return new InvokeConfig(invocation, allowedContracts);
}

function unStakeLiquidityPoolToken(amount, pool) {
  const poolData = contractAPI.getContractByName(pool);
  const scriptHash = poolData.reversePool ? contractAPI.getContractByName('reverseStaking').hash : contractAPI.getContractByName('staking').hash;
  const allowedContracts = [
    scriptHash,
    poolData.hash,
  ];
  const invocation = [{
    scriptHash,
    operation: 'refund',
    args: [
      {
        type: 'Address',
        value: walletAPI.state.walletData.value.address,
      },
      {
        type: 'Integer',
        value: amount,
      },
      {
        type: 'Hash160',
        value: poolData.hash,
      },
    ],
  }];

  return new InvokeConfig(invocation, allowedContracts);
}

export default {
  stakeLiquidityPoolToken,
  unStakeLiquidityPoolToken,
};
