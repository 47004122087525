const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

export const CHAIN_ID_POLY = 0;
export const CHAIN_ID_ETHEREUM = 2;
export const CHAIN_ID_ONTOLOGY = 3;
export const CHAIN_ID_BSC = TARGET_MAINNET ? 6 : 79;
export const CHAIN_ID_NEO = TARGET_MAINNET ? 14 : 88;

export default [
  {
    id: CHAIN_ID_POLY,
    name: 'PolyNetwork',
    url: 'https://poly.network',
    explorerName: 'Transaction Browser',
    explorerUrl: TARGET_MAINNET
      ? 'http://explorer.poly.network/tx/{txHash}'
      : 'http://explorer.poly.network/testnet/tx/{txHash}',
  },
  {
    id: CHAIN_ID_ETHEREUM,
    name: 'Ethereum',
    explorerName: 'Etherscan',
    explorerUrl: TARGET_MAINNET
      ? 'https://etherscan.io/tx/0x{txHash}'
      : 'https://ropsten.etherscan.io/tx/0x{txHash}',
    lockContractHash: TARGET_MAINNET
      ? '0x250e76987d838a75310c34bf422ea9f1AC4Cc906'
      : '0xD8aE73e06552E270340b63A8bcAbf9277a1aac99',
    unlockContractHash: TARGET_MAINNET
      ? '0x14413419452Aaf089762A0c5e95eD2A13bBC488C'
      : '0x726532586C50ec9f4080B71f906a3d9779bbd64F',
  },
  {
    id: CHAIN_ID_BSC,
    name: 'Binance Smart Chain',
    explorerName: 'BscScan',
    explorerUrl: TARGET_MAINNET
      ? 'https://bscscan.com/tx/0x{txHash}'
      : 'https://testnet.bscscan.com/tx/0x{txHash}',
    lockContractHash: TARGET_MAINNET
      ? '0x2f7ac9436ba4B548f9582af91CA1Ef02cd2F1f03'
      : '0x097Ae585BfEf78DDC8E266ABCb840dAF7265130c',
    unlockContractHash: TARGET_MAINNET
      ? '0x1c9Ca8aBb5da65d94DaD2E8Fb3f45535480d5909'
      : '0x906bC93FF690d6D3D2C4E7A7dfA4dEbdEF2cD7CA',
  },
  {
    id: CHAIN_ID_ONTOLOGY,
    name: 'Ontology',
    explorerName: 'Blockchain Explorer',
    explorerUrl: TARGET_MAINNET
      ? 'https://explorer.ont.io/transaction/{txHash}'
      : 'https://explorer.ont.io/transaction/{txHash}/testnet',
    lockContractHash: TARGET_MAINNET
      ? 'de8f16ae1c93eb01a511072bc4a8c2f4c00b0cfe'
      : 'c5e07c29e3088acf4cd02e7b6d521b8e34c0b270',
    unlockContractHash: TARGET_MAINNET
      ? 'de8f16ae1c93eb01a511072bc4a8c2f4c00b0cfe'
      : 'c5e07c29e3088acf4cd02e7b6d521b8e34c0b270',
  },
  {
    id: CHAIN_ID_NEO,
    name: 'Neo',
    explorerName: 'Dora',
    explorerUrl: TARGET_MAINNET
      ? 'https://dora.coz.io/transaction/neo3/mainnet/0x{txHash}'
      : 'https://neo3.testnet.neotube.io/transaction/0x{txHash}',
    nodeUrl: TARGET_MAINNET
      ? 'https://neofura.ngd.network:1927'
      : 'https://testneofura.ngd.network:444',
    lockContractHash: TARGET_MAINNET
      ? 'f8465c7a0700c8ee0bfdb932c7586b66938f90d2'
      : 'e8122a6a59bce83c78ec497c189d4b7a4f397d68',
    unlockContractHash: TARGET_MAINNET
      ? '5ba6c543c5a86a85e9ab3f028a4ad849b924fab9'
      : '618d44dc3af16c6120dbf65402024f40a04f772a',
    vaultContractHashV1: TARGET_MAINNET
      ? '4efac3dd4fdf7037dbd37080f357469439bd840a'
      : '6c2ad62d57c9be47ce90d8927d5aab9a3365cdda',
    vaultClaimableDecimalsV1: 22,
    vaultContractHash: TARGET_MAINNET
      ? '4d92194e8d73980dadbadfc1993b2014c9fbd9da'
      : '53cac28a3737bc43bb38eb32bbf85602c68e6dc5',
    vaultClaimableDecimals: 38,
    swapContractHash: TARGET_MAINNET
      ? '5ea2866235ab389fdd44017059eac95ca9e247aa'
      : 'd40d3133f30fc9f8c76c10cff40370be3320f0bb',
    voteContractHash: TARGET_MAINNET
      ? '64fface9a4216b0685a47d2f9bf0450a3de4fd25'
      : '68040bc5439054e4d7ef73ef63a77c44f9151637',
  },
];
