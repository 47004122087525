/* eslint-disable no-restricted-syntax,no-await-in-loop */
import { sc } from '@cityofzion/neon-js';
import store from '@/store';
import poolData from '@/os/externalData/blockchainData';
import contractAPI from '@/os/APIs/contractAPI';
import rpcAPI from '@/os/APIs/rpcAPI';
import walletAPI from '@/os/APIs/walletAPI';
import axios from 'axios';
import priceAPI from '@/os/APIs/priceAPI';
import BigNumber from 'bignumber.js';

const TARGET_MAINNET = process.env.VUE_APP_TARGET === 'mainnet';

const invokePools = contractAPI.getAllPools();

function stackInvokes(pool) {
  const stakingContract = pool.reversePool === false ? contractAPI.getContractByName('staking').hash : contractAPI.getContractByName('reverseStaking').hash;
  const checkRewardFunction = pool.reversePool === false ? 'checkFLM' : 'check';
  const data = [];
  data.push(sc.createScript({
    scriptHash: stakingContract,
    operation: 'getStakingAmount',
    args: [
      {
        type: 'Hash160',
        value: `0x${walletAPI.state.walletData.value.hash}`,
      },
      {
        type: 'Hash160',
        value: contractAPI.getContractByName(pool.symbol).hash,
      },
    ],
  }));
  data.push(sc.createScript({
    scriptHash: stakingContract,
    operation: checkRewardFunction,
    args: [
      {
        type: 'Hash160',
        value: `0x${walletAPI.state.walletData.value.hash}`,
      },
      {
        type: 'Hash160',
        value: contractAPI.getContractByName(pool.symbol).hash,
      },
    ],
  }));
  data.push(sc.createScript({
    scriptHash: pool.hash,
    operation: 'balanceOf',
    args: [
      {
        type: 'Hash160',
        value: `0x${walletAPI.state.walletData.value.hash}`,
      },
    ],
  }));
  return data;
}

async function formatPoolInvokes(stackedScript) {
  const blockChainData = await rpcAPI.runRawScript(stackedScript);
  const data = {};
  let i = 1;
  for (const pool of Object.values(invokePools)) {
    const lpTokensStaked = new BigNumber(blockChainData[i].value).shiftedBy(-pool.decimals).toNumber();
    data[pool.symbol] = {
      lpTokensStaked,
      unclaimed: new BigNumber(blockChainData[i + 1].value).toNumber(),
      totalLpTokens: new BigNumber(blockChainData[i + 2].value).shiftedBy(-pool.decimals).plus(lpTokensStaked).toNumber(),
    };
    i += 3;
  }
  data.flundAssets = new BigNumber(blockChainData[0].value).shiftedBy(-8).toNumber();
  return data;
}

async function getUserData() {
  const scripts = [];

  for (const pool of Object.values(invokePools)) {
    scripts.push(...stackInvokes(pool));
  }
  scripts.unshift(sc.createScript({
    scriptHash: contractAPI.getContractByName('flund').hash,
    operation: 'balanceOf',
    args: [
      {
        type: 'Hash160',
        value: `0x${walletAPI.state.walletData.value.hash}`,
      },
    ],
  }));

  const data = await formatPoolInvokes(scripts);
  return data;
}

function getReStakeTime(pool, currentWorth) {
  const gasPrice = priceAPI.tokenPrices.value.GASUSDT;
  const thisPoolData = walletAPI.state.poolData.value.filter((p) => p.symbol === pool)[0];
  const costToReStake = 0.6 * gasPrice;
  const apy = thisPoolData.apr / 100;
  const usdPrDay = currentWorth * (apy / 365);

  let bestWorth = 0;
  let bestNumCompound = 0;

  for (let i = 1; i < 365; i += 1) {
    const tmp = ((1 + apy / i) ** i) * currentWorth - costToReStake * i;
    if (tmp > bestWorth) {
      bestNumCompound = i;
      bestWorth = tmp;
    }
  }
  const period = 365 / bestNumCompound;
  const result = {
    periodValue: usdPrDay * period,
    compoundsPrYear: bestNumCompound,
  };
  return result;
}

function getReStakeData(pool, lpTokensStaked, userPool) {
  const reStakeData = getReStakeTime(pool.symbol, new BigNumber(lpTokensStaked).multipliedBy(pool.lpTokenUsdPrice).toNumber());
  let i = 0;
  const aprPrCompound = new BigNumber(pool.apr).dividedBy(reStakeData.compoundsPrYear).dividedBy(100).plus(1);
  const gasPrice = priceAPI.tokenPrices.value.GASUSDT;
  const reStakeGasCost = 0.6;
  let currentValue = new BigNumber(userPool.lpTokenTotalValue);

  while (i < reStakeData.compoundsPrYear) {
    currentValue = currentValue.multipliedBy(aprPrCompound).minus(reStakeGasCost * gasPrice);
    i += 1;
  }

  let userApy = new BigNumber(currentValue).dividedBy(userPool.lpTokenTotalValue)
    .minus(1)
    .multipliedBy(100);

  userApy = userApy.gt(pool.apr) ? userApy.toNumber() : pool.apr;

  return { reStakePeriodValue: reStakeData.periodValue, compoundsPrYear: reStakeData.compoundsPrYear, userApy };
}

export default async function userData() {
  if (!walletAPI.state.conditions.value.poolData) {
    await poolData();
  }
  const pools = store.state.blockchain.poolData;
  const user = {
    unclaimed: {},
    totalStakeValue: 0,
    poolData: [],
    tokens: JSON.parse(JSON.stringify(contractAPI.getAllTokens())),
    counter: {
      startTime: new Date().getTime(),
      usdPrSecond: 0,
    },
  };

  const wallet = walletAPI.state.walletData.value.address;
  let walletPoolData;
  try {
    walletPoolData = await axios.get(`https://api.flamingo.finance/address-info?n3_address=${wallet}&mainnet=${TARGET_MAINNET}`);
  } catch {
    walletPoolData = false;
  }

  const poolCopy = JSON.parse(JSON.stringify(pools));
  const userBlockchainData = await getUserData();

  for (const pool of Object.values(poolCopy)) {
    const apiData = walletPoolData ? walletPoolData.data[pool.symbol] : undefined;
    const { lpTokensStaked, unclaimed, totalLpTokens } = userBlockchainData[pool.symbol];
    const userPool = {
      symbol: pool.symbol,
      unclaimed: 0,
      lpTokensStaked,
      lpTokenTotalValue: 0,
      tokenRewardsPrSecond: 0,
      usdPrSecond: 0,
      claimedTokens: 0,
      reStakePeriodValue: 0,
      compoundsPrYear: 0,
      tokens: [],
    };

    if (typeof apiData !== 'undefined') {
      userPool.claimedTokens = apiData.claimed;
    }

    if (!Object.keys(user.counter).includes(pool.rewardToken.symbol)) {
      user.counter[pool.rewardToken.symbol] = 0;
    }

    if (!Object.keys(user.unclaimed).includes(pool.rewardToken.symbol)) {
      user.unclaimed[pool.rewardToken.symbol] = 0;
    }
    const shiftBy = 30 + pool.rewardToken.decimals;
    userPool.tokensUnclaimed = new BigNumber(unclaimed).shiftedBy(-shiftBy).toNumber();
    userPool.rewardToken = pool.rewardToken;
    user.unclaimed[pool.rewardToken.symbol] += userPool.tokensUnclaimed;

    if (lpTokensStaked > 0) {
      userPool.tokenRewardsPrSecond = (new BigNumber(pool.tokenRewardPrAsset).dividedBy(86400)).multipliedBy(lpTokensStaked).toNumber();
      userPool.usdPrSecond = (new BigNumber(pool.dailyUsdPrAsset).dividedBy(86400)).multipliedBy(lpTokensStaked).toNumber();
      userPool.lpTokenTotalValue = new BigNumber(lpTokensStaked).multipliedBy(pool.lpTokenUsdPrice).toNumber();
      userPool.totalPoolShare = (new BigNumber(lpTokensStaked).dividedBy(pool.totalLpMinted)).multipliedBy(100).toNumber();
      userPool.stakedPoolShare = (new BigNumber(lpTokensStaked).dividedBy(pool.totalLpStaked)).multipliedBy(100).toNumber();
      userPool.totalLpTokenShare = (new BigNumber(totalLpTokens).dividedBy(pool.totalLpMinted)).multipliedBy(100).toNumber();
      user.counter[pool.rewardToken.symbol] += userPool.tokenRewardsPrSecond > 0 ? userPool.tokenRewardsPrSecond : 0;
      user.counter.usdPrSecond += userPool.usdPrSecond > 0 ? userPool.usdPrSecond : 0;
      user.totalStakeValue += userPool.lpTokenTotalValue;

      const reStakeInfo = getReStakeData(pool, lpTokensStaked, userPool);
      userPool.reStakePeriodValue = reStakeInfo.reStakePeriodValue;
      userPool.compoundsPrYear = reStakeInfo.compoundsPrYear;
      userPool.userApy = reStakeInfo.userApy;
    }

    pool.tokens.forEach((token) => {
      if (!Object.keys(user.tokens[token.symbol]).includes('pools')) {
        user.tokens[token.symbol].pools = {};
      }

      const userTokenCount = new BigNumber(token.supply).multipliedBy(userPool.totalLpTokenShare).dividedBy(100).toNumber();
      const userTokenValue = new BigNumber(token.totalValue).multipliedBy(userPool.totalLpTokenShare).dividedBy(100).toNumber();

      const thisToken = {
        symbol: token.symbol,
        userTokenCount: userPool.totalPoolShare > 0 ? userTokenCount : 0,
        userTokenValue: userPool.totalPoolShare > 0 ? userTokenValue : 0,
      };

      if (!Object.keys(user.tokens[token.symbol]).includes('userTotalTokens')) {
        delete user.tokens[token.symbol].supply;
        delete user.tokens[token.symbol].totalValue;
        user.tokens[token.symbol].userTotalTokens = 0;
        user.tokens[token.symbol].userTotalValue = 0;
        user.tokens[token.symbol].fees = 0;
        user.tokens[token.symbol].staked = 0;
      }

      if (typeof apiData !== 'undefined') {
        if (userPool.lpTokensStaked > 0) {
          thisToken.staked = apiData.tokens[token.symbol].staked;
          user.tokens[token.symbol].staked += apiData.tokens[token.symbol].staked;
          thisToken.fees = apiData.tokens[token.symbol].fees;
          user.tokens[token.symbol].fees += apiData.tokens[token.symbol].fees;
        }
      }

      userPool.tokens.push(thisToken);
      user.tokens[token.symbol].userTotalTokens += thisToken.userTokenCount > 0 ? thisToken.userTokenCount : 0;
      user.tokens[token.symbol].userTotalValue += thisToken.userTokenValue > 0 ? thisToken.userTokenValue : 0;
      user.tokens[token.symbol].pools[pool.symbol] = thisToken;
    });
    delete user.tokens.NEO;
    user.poolData.push(userPool);
  }

  user.tokens.FLUND = {
    ...contractAPI.getContractByName('FLUND'),
    userTotalTokens: userBlockchainData.flundAssets,
    userTotalValue: userBlockchainData.flundAssets * priceAPI.getPrice('FLUND'),
    userStakedFlm: 0,
    userStakedValue: 0,
  };

  if (typeof walletPoolData !== 'undefined') {
    user.tokens.FLUND.userFlundShare = new BigNumber(userBlockchainData.flundAssets).dividedBy(store.state.blockchain.flundData.flundTokens).multipliedBy(100).toNumber();
    user.tokens.FLUND.userStakedFlm = walletPoolData.data.flund_invested_value_flm;
    user.tokens.FLUND.userStakedValue = walletPoolData.data.flund_invested_value;
  }

  store.commit('blockchain/setUserFlundData', userBlockchainData.flundAssets);
  store.commit('blockchain/setUserPoolData', user);
  store.commit('blockchain/newCounter');
  return user;
}
