import { wallet as NeonWallet } from '@cityofzion/neon-js';

export class WrongNetError extends Error {
  constructor({
    isUsingLegacy,
    target,
  }) {
    // eslint-disable-next-line no-nested-ternary
    const message = (isUsingLegacy === true)
      ? 'Wallet is connected to wrong blockchain, please switch to N3!'
      : target === 'mainnet'
        ? 'Wallet is connected to TestNet, please switch to MainNet!'
        : 'Wallet is connected to MainNet, please switch to TestNet!';

    super(message);
  }
}

const b58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

function base58ToDec(addr) {
  let dec = 0n;

  addr.split('').forEach((char) => {
    // eslint-disable-next-line no-undef
    dec = dec * 58n + BigInt(b58.indexOf(char));
  });

  return dec;
}

function reverseHex(hex) {
  return hex.match(/[a-fA-F0-9]{2}/g).reverse().join('');
}

function decode(addr) {
  const dec = base58ToDec(addr);
  const hex = dec.toString(16).slice(2, -8);
  return reverseHex(hex);
}

function addressToHash(address) {
  return NeonWallet.getScriptHashFromAddress(address);
}

export default {
  decode,
  addressToHash,
  WrongNetError,
};
