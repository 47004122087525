import { sc, wallet as NeonWallet } from '@cityofzion/neon-js';

export function invocationsToScript(invocations) {
  let allScripts = '';

  invocations.forEach((invocation) => {
    const args = invocation.args.map((arg) => ({
      type: arg.type !== 'Address' ? arg.type : 'Hash160',
      value: arg.type !== 'Address' ? arg.value : `0x${NeonWallet.getScriptHashFromAddress(arg.value)}`,
    }));

    allScripts += sc.createScript({
      scriptHash: invocation.scriptHash,
      operation: invocation.operation,
      args,
    });
  });

  return allScripts;
}

export default function InvokeConfig(invocations, allowedContracts) {
  this.invocations = invocations;
  this.allowedContracts = allowedContracts;
}
